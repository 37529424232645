import React, { useState, useEffect } from 'react';
import { EyeIcon, EyeOffIcon, User, Lock } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../redux/slice/authSlice';
import {useGoogleLogin} from '@react-oauth/google'

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await dispatch(signIn(userData));
    if (localStorage.getItem('token')) {
      navigate('/');
      window.location.reload();
    }
  };

  // const responseGoogle = (response) => {
  //   console.log(response);
  // }
  //
  // const handleGoogleLogin = useGoogleLogin({
  //   onSuccess: responseGoogle,
  //   onError: responseGoogle,
  // })

  const handleGoogleLogin =() => {
    window.location.href = 'https://digimationserver.digimationflight.com/auth/google';
  }


  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth.error) {
      setError(auth.error);
    }
  }, [auth]);

  return (
    <div className='relative h-screen w-full max-w-full my-0 mx-auto py-20 bg-gradient-to-b from-yellow-200 to-yellow-500 px-15 flex flex-col items-center overflow-hidden'>
      {/* Background Circles - Top Left */}
      <div className='absolute top-0 left-0 w-[800px] h-[800px] -translate-x-1/2 -translate-y-1/2'>
        <div className='absolute inset-0 rounded-full bg-[#FFC500] opacity-50'></div>
        <div className='absolute inset-[10%] rounded-full bg-yellow-300 opacity-50'></div>
        <div className='absolute inset-[20%] rounded-full bg-yellow-200 opacity-50'></div>
        <div className='absolute inset-[30%] rounded-full bg-yellow-100 opacity-50'></div>
      </div>
      {/* Background Circles - Bottom Right */}
      <div className='absolute bottom-0 right-0 w-[800px] h-[800px] translate-x-1/2 translate-y-1/2'>
        <div className='absolute inset-0 rounded-full bg-[#FFC500] opacity-50'></div>
        <div className='absolute inset-[10%] rounded-full bg-yellow-300 opacity-50'></div>
        <div className='absolute inset-[20%] rounded-full bg-yellow-200 opacity-50'></div>
        <div className='absolute inset-[30%] rounded-full bg-yellow-100 opacity-50'></div>
      </div>

      <div className=' relative p-10 m bg-white flex flex-col rounded-2xl w-96 shadow-neutral-600 shadow-lg'>
        <h1 className='text-2xl p-0 font-bold text-neutral-950 '>Sign In</h1>
        <form onSubmit={handleLogin} className='flex flex-col w-full'>
          <div className="relative w-full mt-5">
            <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Email"
            />
            <User className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
          </div>

          <div className="relative w-full mt-5">
            <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={userData.password}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl  placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Password"
            />
            <button
                type="button"
                className="absolute inset-y-0 left-0 pl-3 flex items-center"
            >
              <Lock className="h-4 w-4 text-neutral-400"/> {/* Lock icon */}
            </button>
            <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={handleShowPassword}
            >
              {!showPassword ? (
                  <EyeOffIcon className="h-4 w-4 text-neutral-400"/>
              ) : (
                  <EyeIcon className="h-4 w-4 text-neutral-400"/>
              )}
            </button>
          </div>

          <div className="flex justify-between items-center mt-5 mb-10">
            <div>
              <input
                  type="checkbox"
                  id="remember"
                  className="w-3 h-3 rounded border-neutral-300 text-yellow-400 focus:ring-yellow-400"
              />
              <label htmlFor="remember" className="ml-2 text-sm text-neutral-400 font-semibold">
                Remember me
              </label>
            </div>
            <div>
              <Link to="/auth/forgotPassword" className="text-yellow-500 hover:text-yellow-600 font-medium"> Forgot
                Password?</Link>
            </div>

          </div>

          <button
              type="submit"
              className="bg-yellow-500 shadow-lg shadow-neutral-400 rounded-2xl text-white mx-10 mt-10 py-3 px-2 rounded-2 font-montserrat"
          >
            Sign In
          </button>
          <div className="mt-6 text-center text-sm">
            <span className="text-neutral-600">Don't have an Account? </span>
            <Link to="/register" className="text-yellow-500 hover:text-yellow-600 font-medium">
              Register
            </Link>
          </div>
          <div className="relative w-full flex items-center justify-center my-6">
            <div className="absolute w-full border-t border-neutral-200"></div>
            <div className="relative bg-white px-4 text-sm text-neutral-500">Or</div>
          </div>

        </form>
        <button
            onClick={handleGoogleLogin}
            type="button"
            className="w-full flex items-center justify-center gap-2 border border-neutral-200 rounded-lg py-3 px-4 hover:bg-neutral-50 transition-colors"
        >
          <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5"/>
          <span className="text-neutral-700">Sign in with Google</span>
        </button>

        {error && (
            <div className='mt-4 text-red-500 text-sm text-center'>
              {error.message || 'Login failed. Please try again.'}
            </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;