import React from 'react';
import eclipse from '../assets/image/Eclipse.png'
import webSerice from '../assets/image/WebServices.png';
import Digita from '../assets/image/DigitalMarketing.png';
import Train from '../assets/image/TrainingProgram.png'
import NewDigi from '../assets/image/DigitaMarketing.png'
import Graphic from '../assets/image/GraphicDesign.png'
import NewData from '../assets/image/newDataSicence.png'
import {motion} from "framer-motion";
import "./service.css"
import {Link} from "react-router-dom";

const Service = () => {
    return (<>

        <div className='bg-[#18171D]  font-montserrat '>

            <div className='relative '>
                <div className=' hidden lg:felx absolute -left-36 top-16 h-[450px] w-[450px] rounded-full bg-flight-500/10 blur-2xl'/>
                <div className='container mx-auto py-10 px-4'>

                    <h2 className='text-center text-2xl lg:text-3xl font-bold font-montserrat text-white py-5'>
                        Popular Courses
                    </h2>
                    <p className='text-[#E4E3E8]/50 text-center text-base lg:text-xl font-montserrat'>
                        Let’s join our best classes with our famous instructor & institutes
                    </p>

                    <div className='flex flex-col md:flex-row  justify-center items-center gap-14 my-10'>
                        <div className='flex flex-col gap-4 hover:scale-90 duration-150'>
                            <img src={NewDigi} alt='Digital Marketing' className='w-64 h-64'/>
                            <h1 className='text-white text-center font-bold text-xl font-montserrat'>
                                Digital Marketing
                            </h1>
                        </div>
                        <div className='flex flex-col gap-4 hover:scale-90 duration-150'>
                            <img src={NewData} alt='Data Science' className='w-64 h-64'/>
                            <h1 className='text-white text-center font-bold text-xl font-montserrat'>
                                Data Science
                            </h1>
                        </div>
                        <div className='flex flex-col gap-4 hover:scale-90 duration-150'>
                            <img src={Graphic} alt='Graphic Design' className='w-64 h-64'/>
                            <h1 className='text-white text-center font-bold text-xl font-montserrat'>
                                Graphic Design
                            </h1>
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-20'>
                        <Link to='/training'>


                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}} className='border-2 border-flight-500 py-4 px-7 text-flight-500 rounded-lg hover:bg-white hover:text-black '>
                            <p className=' text-center text-lg font-medium' >Explore All Courses</p>
                        </motion.button>
                        </Link>
                    </div>
                </div>
            </div>


        </div>
    </>);
};

export default Service;