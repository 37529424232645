import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUser } from './redux/slice/userSlice';
import HomeHOC from './HOC/Home.HOC';
import HomePage from './pages/HomePage';
import about from './components/about';
import Training from './components/training';
import MasterClass from './components/masterClass';
import Contact from './components/contact';
import Blog from './components/blog';
import SignIn from './components/auth';
import SignUp from './components/auth/Signup';
import ForgetPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import Custom404 from './components/404';
import VerifyOtp from './components/auth/verifyOtp';
import CampusAmbassdor from './components/CampusAmbassdor';
import AdminAuth from './components/auth/AdminAuth';
import AdminHoc from './HOC/Admin.HOC';
import DashboardPage from './pages/DashboardPage';
import AddCourse from './components/admin/addCourse';
import CoursePage from './components/CoursePage/CoursePage';
import Profile from './components/Profile';
import PrivacyPolicy from "./components/PrivacyPolicy";
import GoogleCallbackHandler from "./components/GoogleCallbackHandler";
import ComingSoon from "./components/CommingSoon";


function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(fetchUser());
        }
    }, [dispatch]);

    return (
        <>
            <Routes className="section">
                <Route path="/" element={<HomeHOC component={HomePage} />} />
                <Route path="/about" element={<HomeHOC component={about} />} />
                <Route path="/training" element={<HomeHOC component={Training} />} />
                <Route path="/masterClass" element={<HomeHOC component={MasterClass} />} />
                <Route path="/contact" element={<HomeHOC component={Contact} />} />
                <Route path="/blogs" element={<HomeHOC component={Blog} />} />
                <Route path="/profile" element={<HomeHOC component={Profile} />} />
                <Route path="/auth" element={<SignIn />} />
                <Route path="/register" element={<SignUp />} />
                <Route path="/verifyOtp" element={<HomeHOC component={VerifyOtp} />} />
                <Route path="/campusAmbassador" element={<HomeHOC component={CampusAmbassdor} />} />
                <Route path="/auth/forgotPassword" element={<ForgetPassword />} />
                <Route path="/resetpassword/:token" element={<ResetPassword />} />
                <Route path="/coursePage" element={<HomeHOC component={CoursePage} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/authenticate" element={<GoogleCallbackHandler />} />
                <Route path="/courses/comingSoon" element={<ComingSoon/>}/>

                {/* Admin Panel */}
                <Route path="/digimation/users/admin" element={<AdminAuth />} />
                <Route path="/dgimation/user/dashboard" element={<AdminHoc component={DashboardPage} />} />
                <Route path="/digimation/user/course/add" element={<AdminHoc component={AddCourse} />} />

                <Route path="*" element={<Custom404 />} />
            </Routes>
        </>
    );
}

export default App;