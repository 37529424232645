import React, {useEffect, useState} from 'react';
import {Pencil} from 'lucide-react';
import avatar from '../assets/image/Ellipse 212.png';
import {useSelector, useDispatch} from "react-redux";
import {updateUser} from "../redux/slice/userSlice";

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [error, setError] = useState(null);
    const [nameError, setNameError] = useState(null);

    useEffect(() => {
        setUserData(user.user);
    }, [user]);

    const token = localStorage.getItem("token");

    const [editName, setEditName] = useState(false);
    const onEdit = () => {
        setEditName(!editName);
    };
    const [editPersonalDetails, setEditPersonalDetails] = useState(false);

    const onEditPersonalDetails = () => {
        setEditPersonalDetails(!editPersonalDetails);
    }

    const onSaveName = () => {
        let errorMessage = null
        if (userData.userName === "") {
            errorMessage = ("Name cannot be empty");
        }
        if (errorMessage) {
            setNameError(errorMessage)
        } else {
            setNameError(null);
            dispatch(updateUser(userData));
            setEditName(false);
        }
    }

    const onSavePersonalDetails = async () => {
        let errorMessage = null;

        if (userData.phoneNumber.length !== 10) {
            errorMessage = ("Phone Number must be of 10 digits");
        }
        if (!userData.phoneNumber.startsWith('6') && !userData.phoneNumber.startsWith('7') && !userData.phoneNumber.startsWith('8') && !userData.phoneNumber.startsWith('9')) {
            errorMessage = errorMessage ? `${errorMessage}, Phone Number must start with 6, 7, 8, or 9` : "Phone Number must start with 6, 7, 8, or 9";
        }
        if (errorMessage) {
            setError(errorMessage);
        } else {
            setError(null);
            dispatch(updateUser(userData));
            setEditPersonalDetails(false);
        }
    }


    const onHandleChnage = ( e ) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    }

    if (!token) {
        window.location.href = "/auth";
    }

    if (userData === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="relative w-full max-w-full mx-auto px-10 p-4 mb-20 font-montserrat">
            {/* Header */}
            <div className="relative w-full h-32 bg-gradient-to-r from-yellow-100 to-orange-100 mb-16 overflow-hidden">
                <h1 className="absolute inset-0 flex items-center justify-center text-2xl font-semibold">
                    My Profile
                </h1>
            </div>

            {/* Main Content */}
            <div className="flex flex-col items-center gap-6">
                {/* Profile Card */}
                <div className="bg-white w-full max-w-3xl rounded-lg p-6 border-2 border-neutral-200 shadow-sm">

                    {
                        nameError && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                 role="alert">
                                <span className="block sm:inline">{nameError}</span>
                            </div>
                        )
                    }

                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                            <div className="w-28 h-28 rounded-full flex items-center justify-center overflow-hidden">
                                <img src={avatar} alt="avatar"/>
                            </div>
                            <div>
                                {
                                    editName ? (
                                        <input
                                            type="text"
                                            name="userName"
                                            className="border border-gray-300 rounded-lg px-2 py-1"
                                            placeholder="Enter your name"
                                            value={userData?.userName}
                                            onChange={onHandleChnage}
                                        />
                                    ) : <h2 className="text-xl font-semibold mb-2">{userData?.userName}</h2>
                                }

                                <p className="text-gray-600 mb-2">Digimation</p>


                                <p className="text-gray-500 text-sm">Aligarh, Uttar Pradesh</p>
                            </div>
                        </div>
                        {editName ? (
                            <button
                                className="py-2 px-4 rounded-md bg-yellow-500 text-white flex flex-row gap-2 justify-center items-center border border-gray-300 hover:text-black hover:bg-gray-100"
                                onClick={onSaveName}
                            >
                                <span>Save</span>
                            </button>
                        ) : (
                            <button
                                className="py-2 px-4 rounded-full flex flex-row gap-2 justify-center items-center border border-gray-300 text-black hover:bg-gray-100"
                                onClick={onEdit}
                            >
                                <span>Edit</span>
                                <Pencil className="h-4 w-4"/>
                            </button>
                        )}
                    </div>
                </div>

                {/* Personal Information */}
                <div className="bg-white w-full max-w-3xl rounded-lg p-6 border-2 border-neutral-200 shadow-sm">
                    {
                        error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                 role="alert">
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )
                    }
                    <div className="flex items-center justify-between mb-6">
                        <h3 className="text-lg font-semibold">Personal Information</h3>


                        {editPersonalDetails ? (
                            <button
                                className="py-2 px-4 rounded-md bg-yellow-500 text-white flex flex-row gap-2 justify-center items-center border border-gray-300 hover:text-black hover:bg-gray-100"
                                onClick={onSavePersonalDetails}
                            >
                                <span>Save</span>
                            </button>
                        ) : (
                            <button
                                className="py-2 px-4 rounded-full flex flex-row gap-2 justify-center items-center border border-gray-300 text-black hover:bg-gray-100"
                                onClick={onEditPersonalDetails}
                            >
                                <span>Edit</span>
                                <Pencil className="h-4 w-4"/>
                            </button>
                        )}
                    </div>
                    <div className="space-y-4">
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label className="text-sm font-semibold text-gray-400">Email Address</label>
                                <p className="font-medium mt-2">{userData?.email}</p>
                            </div>
                            <div>
                                <label className="text-sm font-semibold text-gray-400">Phone</label>

                                {
                                    editPersonalDetails ? (
                                        <input
                                            type="tel"
                                            name="phoneNumber"
                                            className="border border-gray-300 rounded-lg px-2 py-1"
                                            placeholder="Enter your Phone Number"
                                            value={userData?.phoneNumber}
                                            onChange={onHandleChnage}
                                            maxLength={10}

                                        />
                                    ) : (<p className="font-medium mt-2">{userData?.phoneNumber}</p>)
                                }
                            </div>
                        </div>
                        <div>
                            <label className="text-sm font-semibold text-gray-400">Organization</label>
                            <p className="font-medium mt-2">Digimation Flight</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
