import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import {useDispatch} from "react-redux";
import {subscribe} from "../redux/slice/subscribe.slice";
import {Link} from "react-router-dom";


const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    useEffect(() => {
        const targetDate = new Date('2025-01-21T00:00:00')

        const updateTimer = () => {
            const now = new Date()
            const difference = targetDate.getTime() - now.getTime()

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24))
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
                const minutes = Math.floor((difference / 1000 / 60) % 60)
                const seconds = Math.floor((difference / 1000) % 60)

                setTimeLeft({ days, hours, minutes, seconds })
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
            }
        }

        // Update immediately and then every second
        updateTimer()
        const interval = setInterval(updateTimer, 1000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className="flex justify-center space-x-4 text-2xl font-bold">
            {Object.entries(timeLeft).map(([key, value]) => (
                <motion.div
                    key={key}
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * Object.keys(timeLeft).indexOf(key) }}
                >
                    <span className="text-4xl">{value}</span>
                    <span className="text-sm uppercase">{key}</span>
                </motion.div>
            ))}
        </div>
    )
}

const EmailForm = () => {
    const [email, setEmail] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('notified')) {
            setIsSubmitted(true)
        }
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(subscribe({email}))
        setIsSubmitted(true)
        localStorage.setItem('notified', 'true')
    }

    return (
        <motion.form
            onSubmit={handleSubmit}
            className="mt-8 flex flex-col items-center space-y-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
        >
            {!isSubmitted ? (
                <>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-64 rounded-md border border-gray-300 px-4 py-2 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200"
                        required
                    />
                    <motion.button
                        type="submit"
                        className="rounded-md bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Notify Me
                    </motion.button>
                </>
            ) : (
                <>

                    <motion.p
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="text-green-500"
                    >
                        Thank you! We'll notify you when we launch.
                    </motion.p>
                    <Link to='/'>
                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}  className="rounded-md bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600">
                            <p className=' text-center text-lg font-medium' >Go to Home Page</p>
                        </motion.button>
                    </Link>
                </>


            )}
        </motion.form>
    )
}

export default function ComingSoon() {
    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100 px-4 text-center">
            <motion.h1
                className="mb-4 text-5xl font-bold text-gray-800"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Coming Soon
            </motion.h1>
            <motion.p
                className="mb-8 text-xl text-gray-600"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                We're working hard to bring you something amazing. Stay tuned!
            </motion.p>
            <CountdownTimer />
            <EmailForm />
        </div>
    )
}

