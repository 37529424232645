import React, {useState, useEffect} from "react";
import CourseModule from "./CourseModule";
import {ArrowDownToLine} from 'lucide-react';
import {motion} from "framer-motion";
import typing from "../../assets/image/Typing.png";
import vectorimg from "../../assets/image/Vector 100.png";
import notebookimg from "../../assets/image/Notebook.png";
import {Dialog, DialogPanel, DialogTitle} from '@headlessui/react'

const CoursePageContent = ( {syllabus} ) => {
    const [isVisible, setIsVisible] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className="w-full mt-20">
            <div className='flex flex-col'>
                <motion.div
                    className='flex justify-between'
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50}}
                    transition={{duration: 0.5}}
                >
                    <CourseModule {...syllabus[0]} />
                    <img src={typing} alt="Typing" className= " hidden md:flex w-1/3 transition-transform duration-300 hover:scale-105"/>
                </motion.div>
                <motion.div
                    className='hidden md:flex justify-center items-center my-8'
                    initial={{opacity: 0, scaleX: 0}}
                    animate={{opacity: isVisible ? 1 : 0, scaleX: isVisible ? 1 : 0}}
                    transition={{duration: 0.5, delay: 0.3}}
                >
                    <img src={vectorimg} alt="Vector" className="w-1/3"/>
                </motion.div>
                <motion.div
                    className='hidden md:flex justify-between'
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: isVisible ? 1 : 0, y: isVisible ? 0 : -50}}
                    transition={{duration: 0.5, delay: 0.6}}
                >
                    <img src={notebookimg} alt="Notebook"
                         className="w-1/3 transition-transform duration-300 hover:scale-105"/>
                    <CourseModule {...syllabus[1]} />
                </motion.div>
            </div>
            <motion.div
                className='flex flex-col justify-center items-center mt-12'
                initial={{opacity: 0, scale: 0.9}}
                animate={{opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.9}}
                transition={{duration: 0.5, delay: 0.9}}
            >
                <h1 className='font-montserrat text-2xl font-bold mx-10 mb-4'>For More Detail Download Syllabus</h1>
                <div className='mx-10'>
                    <button onClick={() => setIsOpen(true)}
                            className='px-7 py-3 border-2 border-flight-500 flex gap-2 hover:bg-amber-50 transition-all duration-300 ease-in-out hover:scale-105'
                    >
                        <ArrowDownToLine size={24}/>
                        <p>Download Syllabus</p>

                    </button>
                    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50 w-96">
                        {isOpen && (
                            <motion.div
                                className="fixed inset-0 bg-black/50 z-0"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            />
                        )}
                        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 50 }}
                                transition={{ duration: 0.4, ease: 'easeInOut' }}
                            >
                                <DialogPanel className="max-w-lg space-y-4 border bg-neutral-800 p-4 rounded-lg shadow-lg">
                                    <DialogTitle>
                                        <h1 className="text-white text-2xl">Download Syllabus</h1>
                                    </DialogTitle>
                                    <div className="bg-white p-4 rounded-lg">
                                        <form>
                                            <div className="flex flex-col gap-4">
                                                <label htmlFor="name" className="text-sm">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="border border-gray-300 p-2 rounded-md"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-4 my-4">
                                                <label htmlFor="email" className="text-sm">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="border border-gray-300 p-2 rounded-md"
                                                />
                                            </div>
                                            <div className="flex justify-center items-center my-4">
                                                <button
                                                    onClick={() => setIsOpen(false)}
                                                    className="bg-flight-500 text-white p-2 rounded-md"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </DialogPanel>
                            </motion.div>
                        </div>
                    </Dialog>
                </div>
            </motion.div>
        </div>
    );
};

export default CoursePageContent;

