import React from 'react'
import { ThumbsUp, Award, Smile, Lightbulb } from 'lucide-react'

const features = [
  {
    icon: ThumbsUp,
    title: "Diver Courses",
    description: "Wide range of topics for all learners",
  },
  {
    icon: Award,
    title: "Certificate",
    description: "Get certified to boost your career",
  },
  {
    icon: Smile,
    title: "Expert Guidance",
    description: "Learn from Industry Professionals",
  },
  {
    icon: Lightbulb,
    title: "Flexible Learning",
    description: "Study anytime, anywhere",
  },
]

export default function WhatWeOffer() {
  return (
    <div className="w-full max-w-full mx-auto px-4 py-12 sm:py-16 md:py-20 lg:py-24 bg-gray-50">
      <div className="text-center space-y-4 mb-8 sm:mb-10 md:mb-12">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">What We Offer</h2>
        <p className="text-gray-600 text-base sm:text-lg max-w-3xl mx-auto px-4 sm:px-6 md:px-8">
          "Empowering your learning journey with quality courses, expert guidance, and recognized certifications."
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 pt-5 mx-4 sm:mx-8 md:mx-12 lg:mx-20">
        {features.map((feature, index) => (
          <div
            key={feature.title}
            className="bg-white rounded-xl pb-8 sm:pb-10 p-4 sm:p-6 shadow-[10px_-10px_20px_rgba(0,0,0,0.15)] hover:shadow-xl transition-shadow"
          >
            <div className="w-12 h-12 sm:w-14 sm:h-14 mb-4 sm:mb-6 rounded-lg bg-gradient-to-br from-yellow-400 to-orange-400 flex items-center justify-center">
              <feature.icon className="w-6 h-6 sm:w-7 sm:h-7 text-white" />
            </div>
            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
              {feature.title}
            </h3>
            <p className="text-gray-600 text-sm sm:text-base p-1">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

