import React from 'react';
import banner from '../../assets/image/BannerSpot.png';
import hh from '../../assets/image/hh.png'

const TopBanner = ( {title, subTitle} ) => {
    console.log(title);
    return (
        <>
            <div className='bg-[#18171D] h-[450px]'>
                <div className='h-72 md:h-[300px] relative'>
                    <div className='absolute inset-0 -top-8 flex justify-center items-center'>
                        <img src={hh} alt="baaner" className='w-auto '/>
                    </div>
                    <div className='flex flex-col absolute inset-0 top-14 md:-top-0 justify-center items-center z-10'>
                        <h2 className='text-white text-2xl md:text-3xl font-bold text-center font-montserrat'>{title}</h2>
                        <p className='text-white font-montserrat text-center text-base md:text-lg pt-2 md:pt-10 '>
                            " {subTitle} "
                        </p>
                    </div>
                </div>

                {/* Banner Mobile */}
                <div className="md:hidden grid grid-cols-2">
                    <div className="my-2 mx-auto flex min-h-12 items-center justify-center">
                        <div className="h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                            <div
                                className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                <h1 className="text-base font-montserrat font-medium text-white">Mobile Friendly</h1>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto flex min-h-12 items-center justify-center">
                        <div className="h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                            <div
                                className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                <h1 className="text-base font-montserrat font-medium text-white">6-Week Course</h1>
                            </div>
                        </div>
                    </div>
                    <div
                        className="my-2 mx-auto flex min-h-12 items-center justify-center col-span-2">
                        <div className="h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                            <div
                                className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                <h1 className="text-base font-montserrat font-medium text-white">Certified</h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Banner Desktop example */}
                <div className='hidden md:grid grid-cols-2 justify-center items-center mx-20 '>
                    <div className='flex justify-center items-center'>
                        <div className="   w-fit flex min-h-12  items-center justify-center">
                            <div
                                className=" h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                                <div
                                    className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                    <h1 className="text-base font-montserrat font-medium text-white">Mobile
                                        Friendly</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center items-center'>
                        <div className="   w-fit flex min-h-12  items-center justify-center">
                            <div
                                className=" h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                                <div
                                    className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                    <h1 className="text-base font-montserrat font-medium text-white">Projects</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' ml-96 flex justify-center items-center'>
                        <div className="   w-fit flex min-h-12  items-center justify-center">
                            <div
                                className=" h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                                <div
                                    className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                    <h1 className="text-base font-montserrat font-medium text-white">6 Week Course</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' mr-96 flex justify-center items-center'>
                        <div className="   w-fit flex min-h-12  items-center justify-center">
                            <div
                                className=" h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                                <div
                                    className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                    <h1 className="text-base font-montserrat font-medium text-white">Skills</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center col-span-2'>
                        <div className="   w-fit flex min-h-12  items-center justify-center">
                            <div
                                className=" h-12 w-full rounded-full bg-gradient-to-r from-flight-500 to-[#18171D] p-0.5">
                                <div
                                    className="flex h-full w-40 rounded-full items-center justify-center bg-[#18171D] back">
                                    <h1 className="text-base font-montserrat font-medium text-white">Certified</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};

export default TopBanner;