import React from 'react';
import img1 from "../assets/image/scholar1.jpeg";
import img2 from "../assets/image/scholar2.jpeg";
import img3 from "../assets/image/scholar3.jpeg";
import img4 from "../assets/image/scholar4.jpeg";
import AboutComponenets from './AboutComponenets';

const About = () => {
    const students = [
        { src: img1, alt: "Scholar 1" },
        { src: img2, alt: "Scholar 2" },
        { src: img3, alt: "Scholar 3" },
        { src: img4, alt: "Scholar 4" },
    ];

    return (
        <>
            <div className="w-full max-w-full pb-10 pt-28 mx-auto relative bg-white overflow-hidden">
                {/* Decorative Circles */}
                {/* Large circle on bottom left */}
                <div className="absolute top-52 left-14 w-28 h-28 md:w-24 md:h-24 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-lg" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />
                
                {/* Medium circle on top left */}
                <div className="absolute top-32 left-44 w-14 h-14 md:w-12 md:h-12 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-md" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />
                
                {/* Small circle in middle top */}
                <div className="absolute top-24 left-1/3 w-6 h-6 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-sm" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />
                
                {/* Small circle near top right */}
                <div className="absolute top-26 right-1/3 w-6 h-6  rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-sm" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />
                
                {/* Large circle on top right */}
                <div className="absolute top-12 right-48 w-28 h-28 md:w-24 md:h-24 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-lg" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />
                
                {/* Small circle on bottom right */}
                <div className="absolute top-72 right-8 w-12 h-12 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-700 shadow-sm" style={{ filter: 'brightness(1.2) contrast(1.1)' }} />

                {/* Content */}
                <div className="relative z-10 flex flex-col items-center justify-center min-h-[300px] text-center px-4">
                    <h1 className="text-3xl font-montserrat md:text-4xl lg:text-5xl font-bold text-navy-900 mb-6">
                    About Digimation flight
                    </h1>
                    <p className="text-base md:text-lg font-montserrat lg:text-xl text-gray-600 max-w-2xl mb-8">
                    Empowering learners worldwide through innovative e-learning solutions and transformative educational experiences.
                    </p>
                </div>

                {/* Student Grid */}
                <div className="w-full max-w-[1244px] mx-auto px-4 my-12">
                    <div className="flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6">
                        {students.map((student, index) => (
                            <div
                                key={index}
                                className={`w-full sm:w-[250px] h-[380px] relative ${
                                    index !== 1 && index !== 3
                                        ? 'sm:-mt-24'
                                        : 'sm:mt-8 sm:translate-y-[10px] lg:translate-y-0'
                                }`}
                                style={{
                                    borderRadius: '180px 180px 180px 180px',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={student.src}
                                    alt={student.alt}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <AboutComponenets />
        </>
    );
};

export default About;
