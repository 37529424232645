import React from 'react';
import './course.css'
import AboutCourse from "./AboutCourse";
function CourseHero({description, price}) {
    return (
        <>
            <div>
                <div className=" hidden md:flex custom-shape-divider-top-1735387240">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                         preserveAspectRatio="none">
                        <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                              className="shape-fill"></path>
                    </svg>

                </div>
                <div >
                <AboutCourse description={description} price={ price} />
            </div>
            </div>
        </>
    );
}

export default CourseHero;