import React from "react";
import Scholarimg from "../assets/image/scholar.png";
import { Book, Wallet2, Clock, Cog } from 'lucide-react';

const WhyChooseUs = () => {
  const features = [
    {
      icon: <Book className="w-6 h-6 text-white" />,
      title: "Up to Date Course Content",
    },
    {
      icon: <Wallet2 className="w-6 h-6 text-white" />,
      title: "Affordable and Transparent Pricing",
    },
    {
      icon: <Clock className="w-6 h-6 text-white" />,
      title: "Lifetime access to learning Resource",
    },
    {
      icon: <Cog className="w-6 h-6 text-white" />,
      title: "Hands-on Projects to build real world Projects",
    }
  ];

  return (
    <div className="w-full max-w-full mx-auto px-10 sm:px-6 md:px-10 lg:px-24 py-8 sm:py-12 lg:py-20 bg-gray-50 font-montserrat">
      <div className="flex flex-wrap-reverse lg:flex-nowrap gap-8 sm:gap-12 lg:gap-16 justify-center items-center">
        {/* Content Section */}
        <div className="w-full lg:w-1/2 space-y-6 sm:space-y-8 px-4 sm:px-6 lg:px-0">
          <div className="space-y-3 sm:space-y-4 text-center lg:text-left">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-neutral-900">
              Why Choose Us
            </h2>
            <p className="text-base sm:text-lg md:text-xl text-neutral-900">
              "Because your success is our priority, and we're here to help you achieve it every step of the way."
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 py-6 sm:py-10">
            {features.map((feature, index) => {
              const borderClasses =
                index === 1
                  ? "sm:border-l sm:border-dashed sm:border-neutral-800 sm:border-b"
                  : index === 2
                  ? "sm:border-t sm:border-dashed sm:border-neutral-800 sm:border-r"
                  : "";

              return (
                <div
                  key={index}
                  className={`space-y-3 relative p-4 ${borderClasses}`}
                >
                  <div className="relative z-10 bg-gray-50 flex flex-col items-center sm:items-start">
                    <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-yellow-400 to-orange-400 flex items-center justify-center mb-3">
                      {feature.icon}
                    </div>
                    <h3 className="font-montserrat text-base sm:text-lg text-neutral-500 text-center sm:text-left">
                      {feature.title}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <div className="relative w-full max-w-[600px]">
            <img
              src={Scholarimg}
              alt="Graduate student with diploma"
              className="w-full h-auto object-cover"
              style={{
                maxWidth: "100%",
                height: "auto",
                aspectRatio: "1/1"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
