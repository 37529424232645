import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUP } from '../../redux/slice/authSlice';
import { EyeIcon, EyeOffIcon, User, Lock, Mail, Phone } from 'lucide-react';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    const result = await dispatch(signUP(userData));
    if (signUP.fulfilled.match(result)) {
      // Navigate to verify OTP page if registration is successful
      navigate('/verifyOtp', {
        state: {
          email: userData.email
        }
      });
    } else {
      setError(auth.error?.message);
    }
  };
console.log(error);
  return (
    <div className='relative w-full max-w-full my-0 mx-auto py-20 bg-gradient-to-b from-yellow-200 to-yellow-500 px-15 flex flex-col items-center overflow-hidden'>
      {/* Background Circles - Top Left */}
      <div className='absolute top-0 left-0 w-[800px] h-[800px] -translate-x-1/2 -translate-y-1/2'>
        <div className='absolute inset-0 rounded-full bg-[#FFC500] opacity-50'></div>
        <div className='absolute inset-[10%] rounded-full bg-yellow-300 opacity-50'></div>
        <div className='absolute inset-[20%] rounded-full bg-yellow-200 opacity-50'></div>
        <div className='absolute inset-[30%] rounded-full bg-yellow-100 opacity-50'></div>
      </div>
      {/* Background Circles - Bottom Right */}
      <div className='absolute bottom-0 right-0 w-[800px] h-[800px] translate-x-1/2 translate-y-1/2'>
        <div className='absolute inset-0 rounded-full bg-[#FFC500] opacity-50'></div>
        <div className='absolute inset-[10%] rounded-full bg-yellow-300 opacity-50'></div>
        <div className='absolute inset-[20%] rounded-full bg-yellow-200 opacity-50'></div>
        <div className='absolute inset-[30%] rounded-full bg-yellow-100 opacity-50'></div>
      </div>

      <div className='relative p-10 my-24 bg-white flex flex-col rounded-2xl w-96 shadow-neutral-600 shadow-lg'>
        <h1 className='text-2xl p-0 font-bold text-neutral-950'>Sign Up</h1>
        <form onSubmit={handleRegister} className='flex flex-col w-full'>
          <div className="relative w-full mt-5">
            <input
                type="text"
                name="userName"
                value={userData.userName}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Name"
            />
            <User className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
          </div>

          <div className="relative w-full mt-5">
            <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Email"
            />
            <Mail className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
          </div>

          <div className="relative w-full mt-5">
            <input
                type="tel"
                name="phoneNumber"
                value={userData.phoneNumber}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Phone Number"
            />
            <Phone className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
          </div>

          <div className="relative w-full mt-5">
            <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={userData.password}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Password"
            />
            <Lock className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
            <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                  <EyeIcon className="h-4 w-4 text-neutral-400"/>
              ) : (
                  <EyeOffIcon className="h-4 w-4 text-neutral-400"/>
              )}
            </button>
          </div>

          <div className="relative w-full mt-5">
            <input
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={handleChange}
                className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:text-sm placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
                placeholder="Confirm Password"
            />
            <Lock className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400"/>
            <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                  <EyeIcon className="h-4 w-4 text-neutral-400"/>
              ) : (
                  <EyeOffIcon className="h-4 w-4 text-neutral-400"/>
              )}
            </button>
          </div>

          {error && <div className='mt-3 text-red-500 text-sm'>{error}</div>}

          <button
              type="submit"
              className="bg-yellow-500 shadow-lg shadow-neutral-400 rounded-2xl text-white mx-10 mt-10 py-3 px-2 rounded-2 font-montserrat"
          >
            Sign Up
          </button>

          <div className="mt-6 text-center text-sm">
            <span className="text-neutral-600">Already have an Account? </span>
            <Link to="/auth" className="text-yellow-500 hover:text-yellow-600 font-medium">
              Sign In
            </Link>
          </div>

          <div className="relative w-full flex items-center justify-center my-6">
            <div className="absolute w-full border-t border-neutral-200"></div>
            <div className="relative bg-white px-4 text-sm text-neutral-500">Or</div>
          </div>

          <button
              type="button"
              className="w-full flex items-center justify-center gap-2 border border-neutral-200 rounded-lg py-3 px-4 hover:bg-neutral-50 transition-colors"
          >
            <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5"/>
            <span className="text-neutral-700">Sign up with Google</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;