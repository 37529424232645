import React from 'react';
import {Smile} from "lucide-react";

import { FaTrophy,FaHandshake, FaAddressCard } from "react-icons/fa";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { IoMdLock } from "react-icons/io";
import { MdVisibility } from "react-icons/md";

const ChooseUs = () => {
    const options = [
        {
            title: 'Unmatched Quality',
            icon: <FaTrophy size={40}  color='#fcc509'/>,
        },
        {
            title: 'Student Focus',
            icon:<FaHandshake size={40} color='#fcc509'/>
        },
        {
            title: 'Expert Team',
            icon:<FaAddressCard size={40} color='#fcc509'/>
        },
        {
            title: 'Excellence',
            icon: <BsFillRocketTakeoffFill size={40} color='#fcc509'/>
        },
        {
            title: 'Trust',
            icon: <IoMdLock size={40} color='#fcc509'/>
        },
        {
            title:'Transparency',
            icon: <MdVisibility size={40} color='#fcc509'/>
        }

    ]
    return (
        <>

         <div className='bg-flightDark-950 h-full'  >
                <div className='flex justify-center items-center gap-2 lg:gap-5 '>
                    <h1 className='text-center text-2xl lg:text-3xl text-white py-10 font-bold font-montserrat'>Why Choose Digimation ?</h1>
                    <Smile size={48} color='white'/>
                    {/*<CiFaceSmile size={45} color='white'/>*/}
                </div>
             <div className='bg-[#27262B]  relative mt-5     '>
                 <div className='container mx-auto px-10'>
                     <div className='flex lg:flex-row flex-col justify-between items-center'>
                         <div className='h-fit py-10'>
                             <img src='https://corizo.in/wp-content/themes/techglobiz/images/why-corizo-homepage-gif.gif' alt='GIFS' className=' h-[400px] lg:h-[750px]'/>
                                <h2 className=' text-center text-xl font-montserrat font-medium text-white'> Problems Faces by Students</h2>

                         </div>
                         <div className='flex flex-col justify-center items-center'>
                     <div className=' grid grid-cols-2 gap-10  my-16  '>
                         {
                         options.map((option,index) => (
                             <div key={index} className="mx-auto flex min-h-12 items-center justify-center ">
                                 <div
                                     className="h-36 lg:h-44 w-fit rounded-md bg-gradient-to-b from-flight-500 to-[#18171D] p-1">
                                     <div
                                         className="flex flex-col gap-5 h-full w-24 lg:w-44 rounded-md items-center justify-center bg-[#18171D] back">
                                         <div
                                             className=' p-2 lg:p-4 bg-gradient-to-t from-flight-500 via-flightDark-950 to-flightDark-950 rounded-full lg:h-fit lg:w-fit  h-14 w-14'>
                                                 {option.icon}
                                         </div>
                                         <h1 className="lg:text-lg text-sm text-center font-montserrat font-medium text-white">{option.title}</h1>
                                     </div>
                                 </div>
                             </div>
                         ))
                         }


                     </div>
                             <h2 className=' mb-5 text-center text-xl font-montserrat font-medium text-white'> Solution by Digimation
                             </h2>
                     </div>
                         </div>

                 </div>
             </div>
         </div>

        </>
    );
};

export default ChooseUs;