import React from 'react'
import quote2 from "../assets/image/,,.png";

const WhoWeAre = () => {
  return (
    <div className="w-full max-w-full mx-auto px-4 sm:px-6 md:px-8 lg:px-24 py-12 lg:py-24 bg-yellow-50">
      <div className="relative flex flex-col md:flex-row gap-10 justify-center items-center">
        <img src={quote2} alt='quotes' className="absolute  left-0 top-0 w-10 h-8 sm:w-14 sm:h-10 text-yellow-400 transform scale-x-[-1]" />
        <img src={quote2} alt='quotes' className="absolute right-0 top-0 w-10 h-8 sm:w-14 sm:h-10 text-yellow-400" />
        
        {/* Who We Are */}
        <div className="space-y-4 w-full md:w-1/2 max-w-md mt-10 md:mt-0">
          <h2 className="text-2xl md:text-3xl font-bold text-center text-neutral-950 font-montserrat">Who We Are</h2>
          <p className="text-neutral-950 leading-relaxed text-center sm:text-left px-4 sm:px-4 font-montserrat">
            We are an innovative e-learning platform dedicated to empowering students, professionals, and lifelong learners with accessible and engaging education. Our aim is to bridge the gap between knowledge and learners across the globe.
          </p>
        </div>

        {/* Our Mission */}
        <div className="space-y-4 w-full md:w-1/2 max-w-md">
          <h2 className="text-2xl md:text-3xl text-center font-bold text-neutral-950 font-montserrat">Our Mission</h2>
          <p className="text-neutral-950 leading-relaxed text-center sm:text-left px-4 sm:px-4 font-montserrat">
            Our mission is to make education affordable, convenient, and impactful for everyone. By leveraging cutting-edge technology and expertly designed courses, we aim to inspire growth and unlock potential.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre

