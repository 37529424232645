import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

export const forgotPassword = createAsyncThunk('forgotPassword/forgotPassword', async ( email, thunkAPI ) => {
    try {
        const forgotPassword = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotPassword/api/forgotPassword`, {email})
        return forgotPassword.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const resetPassword = createAsyncThunk('forgotPassword/resetPassword', async ( {password, token}, thunkAPI ) => {
    try {
        const resetPassword = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotPassword/api/reset-password/${token}`, {password})
        return resetPassword.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: {
        status: 'idle',
        error: null,
        success: false
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder.addCase(forgotPassword.pending, ( state ) => {
            state.status = 'loading';
        }).addCase(forgotPassword.fulfilled, ( state, action ) => {
            state.status = 'succeeded';
            state.success = true;
        }).addCase(forgotPassword.rejected, ( state, action ) => {
            state.status = 'failed';
            console.log("Error:", action.payload);
            state.error = action.payload;
        })
            .addCase(resetPassword.pending, ( state ) => {
                state.status = 'loading';
            }).addCase(resetPassword.fulfilled, ( state, action ) => {
                state.status = 'succeeded';
                state.success = true;
            }
        ).addCase(resetPassword.rejected, ( state, action ) => {
            state.status = 'failed';
            console.log("Error:", action.payload);
            state.error = action.payload;
        })
    },
    devTools: true
})

export default forgotPasswordSlice.reducer;