import {configureStore} from "@reduxjs/toolkit";

import authReducer from "./slice/authSlice";
import userReducer from './slice/userSlice';
import subscribeReducer from './slice/subscribe.slice'
import adminAuthReducer from './slice/admin/authSlice'
import courseReducer from './slice/admin/courseSlice'
import forgotPasswordReducer from './slice/forgotPassword.slice'
import faqReducer from './slice/faqSlice'
const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        subscribe: subscribeReducer,
        adminAuth: adminAuthReducer,
        course: courseReducer,
        forgotPassword: forgotPasswordReducer,
        faq: faqReducer
    }
})
export default store;