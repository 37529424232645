import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import ReviewCard from "./ReviewCard";
const Review = () => {

    const reviews = [
        {
            value: 4,
            name: 'Harsh Verma',
            description: '"The user experience is flawless, and the interface is intuitive. This has improved our workflow significantly, making complex tasks much easier to manage."\n'
        },
        {
            value: 4.5,
            name: 'Suhani Varshney',
            description: '"This service has transformed the way we approach digital marketing. The results have been phenomenal, and the support team is always available to help. I highly recommend it!"'
        },
        {
            value: 4,
            name: 'Rajat Sharma',
            description: '"I was struggling with understanding complex topics, but this platform made it so easy! The interactive lessons and hands-on projects helped me grasp concepts I never thought I could. Highly recommended!"'
        },{
        value: 3.5,
        name: 'Riya Sharma',
            description: '"The instructors are super supportive and always ready to help. The resources provided are top-notch, and the quizzes really test your understanding. It’s the best decision I’ve made for my education."',

        },
        {
            value: 4.5,
            name: 'Jahanvi Sharma',
            description: '"I loved the way real-world examples were incorporated into the lessons. It made learning so much more engaging and relevant. I feel confident applying these skills in my career.'
        },{
        value: 5,
            name: 'Karan Kumar',
            description: '"The platform is user-friendly and the lessons are well-structured. The quizzes and projects are challenging, but they really help you understand the material. I’ve learned so much and can’t wait to apply my new skills!"'
        },
        {
            value: 5,
            name: 'Rahul Sharma',
            description: '"The instructors are knowledgeable and passionate about teaching. The lessons are engaging and the projects are fun to work on. I’ve gained valuable skills that I can use in my career. Highly recommend it!"'
        }
    ]


    let settings = {
        dots: false,
       arrows: false,
        autoplay: true,
        infinite: true,
        speed: 900,
        autoplaySpeed: 1000,
        pauseOnHover: true,
        cssEase: "linear",
        slidesToShow: 4,
        slidesToScroll: 1,
    }




    return (
        <div className='hidden lg:flex bg-flightDark-950 flex-col'>
            <div className='container mx-auto px-4 my-16 '>
                <div className='flex flex-col justify-center items-center '>
                    <h2 className='text-white text-3xl font-bold font-montserrat text-center'>
                        What our Students Say About Us ?
                    </h2>
                    <p className='text-center text-[#D5D4D9] text-xl my-5 '>
                        Discover the experiences of our satisfied Students! Read their testimonials to learn
                        how <br/> our services have made a positive impact on their Career.
                    </p>
                </div>
                <div className='my-16'>
                    <Slider {...settings}>
                        {reviews.map((review,index) => (
                            <ReviewCard key={index} review={review}/>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Review;