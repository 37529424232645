import React from 'react'
import WhoWeAre from './WhoWeAre';
import WhatWeOffer from './WhatWeOffer';
import WhyChooseUs from './WhyChooseUs';

const AboutComponenets = () => {
  return (
    <div>
      <WhoWeAre/>
      <WhatWeOffer/>
      <WhyChooseUs/>
    </div>
  )
}

export default AboutComponenets
