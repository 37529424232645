import React, {useEffect} from 'react';
import image from '../assets/image/rectangleTraining.png'
import regi from '../assets/image/4 518.png'
import img5 from '../assets/image/5.png'
import img6 from '../assets/image/6 321970543.png'
import img7 from '../assets/image/7 7.png'
import mobileBanner from '../assets/image/trainingMObile.png'
import thumbImg from '../assets/image/12 Devices.png'
import CourseCard from "./courseCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

//Redux
import {useDispatch, useSelector} from "react-redux";
import {getAllCourse} from "../redux/slice/admin/courseSlice";

const Training = () => {
    const courses = [
        {
            img: 'https://img.freepik.com/premium-vector/hand-drawn-digital-marketing-background_23-2151823972.jpg',
            title: 'Digital Marketing',
        }, {
            img: 'https://img.freepik.com/free-photo/html-css-collage-concept-with-hacker_23-2150061984.jpg',
            title: 'Cyber Secuirity',
        }, {
            img: 'https://img.freepik.com/premium-vector/business-background-with-data-analysis_1302-1545.jpg',
            title: 'Data Science',
        }, {
            img: 'https://nearlearn.com/public/images/ai-training-in-bangalore.jpeg',
            title: 'AI/ML',
        }, {
            img: 'https://img.freepik.com/premium-photo/golden-arrow-with-sales-word_698953-15469.jpg',
            title: 'Sales Mastery',
        },
        {
            img: 'https://img.freepik.com/premium-vector/web-development-concept_107173-16744.jpg',
            title: 'Web Development',
        }
    ]
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllCourse())
    }, [])
    const course = useSelector(state => state.course)
    console.log(course.courses)
    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1000,
        pauseOnHover: true,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    autoplay: false,
                }
            },
            {
                breakpoint: 325,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    }
    return (
        <>
            <div className='flex flex-col '>
                <div className='hidden  relative  md:flex justify-between items-center '>
                    <img src={image} alt='box' className='w-full h-[500px] object-cover '/>
                    <div className='absolute max-w-[900px] px-20 '>
                        <h1 className='  text-balance text-center text-white text-4xl align-middle font-bold font-montserrat  '>
                            Explore our exciting Training & Internship Programs today!
                        </h1>
                        <p className=' w-[700px] font-medium font-montserrat text-2xl text-center text-white'>
                            Empowering the next generation with hands-on experience and guaranteed internships.
                        </p>
                    </div>
                    <div className='absolute right-10'>
                        <img src={thumbImg} className='w-[550px]'/>
                    </div>
                </div>
                <div className='md:hidden relative flex justify-center items-center'>
                    <img src={mobileBanner} alt='box' className='w-full '/>
                    <div className='absolute top-0 left-0 w-full h-full bg-flight-500 bg-opacity-50'/>
                    <h1 className='absolute  top-12 text-balance  text-white text-4xl align-middle font-bold font-montserrat  '>
                        Internship Programs
                    </h1>
                    <p className='absolute top-32  text-center text-balance font-medium font-montserrat text-xl text-white'>
                        Empowering the next generation with hands-on experience.
                    </p>
                </div>
                <div className='container mx-auto px-4 py-10'>
                    <h3 className='text-4xl font-semibold font-montserrat mt-10 mb-10 '> Courses offered </h3>
                    <div className='my-5 p-8  md:p-16 border border-gray-300 rounded-3xl shadow-lg '>
                        <Slider  {...settings} className='w-auto'>

                            {courses.map(( course, index ) => (
                                <CourseCard key={index} courses={course}/>
                            ))}
                        </Slider>
                    </div>

                    <div className='mt-10 py-10'>
                        <h1 className='text-4xl font-semibold font-montserrat text-center'>
                            How It Works
                        </h1>
                        <div className='flex flex-col md:flex-row md:justify-around gap-20 items-center mt-10'>
                            <div className='flex flex-col justify-center items-center '>
                                <img src={regi} alt='register' className=''/>
                                <h1 className='text-2xl font-bold text-center font-montserrat'>Register</h1>
                                <p className='text-lg text-center font-montserrat '>Simple sign-up process.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center w-72'>
                                <img src={img5} alt='register' className=''/>
                                <h6 className='text-2xl font-bold text-center font-montserrat py-2'>Course
                                    Selection</h6>
                                <p className='text-lg text-center font-montserrat '>Choose the right course with
                                    guidance from career counselors.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img src={img6} alt='register' className=''/>
                                <h1 className='text-2xl font-bold text-center font-montserrat'>Mentorship</h1>
                                <p className='text-lg text-center font-montserrat '> Learn from industry experts.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center w-72 '>
                                <img src={img7} alt='register' className=''/>
                                <h1 className='text-2xl font-bold text-center font-montserrat'>Internship &
                                    Placement </h1>
                                <p className='text-lg text-center font-montserrat '>Guaranteed internship and placement
                                    support.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </>
    );
};

export default Training;


