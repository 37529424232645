import { Star, Line } from 'lucide-react'
import React from 'react';
import illustrationimg from "../../assets/image/OBJECTS.png"
import starimg from "../../assets/image/Star 1.png"
import lineimg from "../../assets/image/Line 1.png"
import arrowimg from "../../assets/image/Arrow 1.png"

export default function HeroSection() {
  return (
    <div className="min-h-screen font-montserrat bg-[#18171D] relative overflow-hidden">
      {/* Background circles */}
      <div className="absolute bottom-52 left-0 w-[600px] h-[600px] bg-[#3d3d3c] blur-3xl rounded-full opacity-70 -translate-x-1/2 translate-y-1/2" />
      <div className="absolute top-12 right-0 w-[600px] h-[600px] bg-[#3d3d3c] blur-3xl rounded-full opacity-70 translate-x-1/2 -translate-y-1/2" />
      
      {/* Small dots */}
      {[...Array(22)].map((_, i) => (
        <div 
          key={i}
          className="absolute w-1 h-1 bg-gray-600 rounded-full"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
        />
      ))}

      {/* Arrow elements */}
      <div className="absolute bottom-96 right-80">
        <img src={arrowimg} alt="arrowimg" />
      </div>

      <div className="absolute top-10 right-40">
        <svg width="40" height="40" viewBox="0 0 24 24" className="text-yellow-500">
          <path 
            d="M12 3 L3 21 L21 21 Z" 
            stroke="currentColor" 
            strokeWidth="1"
            fill="none"
          />
        </svg>
      </div>
      
      {/* Content container */}
      <div className="container mx-auto px-4 pt-24 pb-16 relative">
        <div className="max-w-5xl mx-auto text-center mb-20">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
            Turning dreams into careers With
            <br />
            The Best <span className="text-yellow-500 relative inline-block">
              Expert
              <span className="absolute -right-16 top-4 text-yellow-500 "><img src={starimg} className='w-9 h-9' alt="star img" /></span>
              <span className="absolute -bottom-11 right-0 w-full"> <img src={lineimg} alt="line curve" /></span>
            </span>
          </h1>
          <p className="text-gray-400 text-lg text-center md:text-xl mt-12">
            Transforming the future with AI services and training.
          </p>
        </div>

        {/* Illustration */}
        <div className="flex justify-center mb-20">
          <img 
            src={illustrationimg}
            alt="Students jumping"
            className="w-96 h-96 object-contain"
          />
        </div>
      </div>
        {/* Features grid */}
        <div className="grid grid-cols-3 lg:grid-cols-5 gap-0 max-w-full mx-0">
        {/* Professional Certificate */}
        {Array.from({ length: 5 }).map((_, i) => (
            <div
            key={i}
            className={`relative ${
                i % 2 !== 0 ? 'top-10' : ''
            }`}
            >
            {/* Feature Content */}
            {i === 0 && (
                <h3 className="text-white py-8 px-6 h-32 bg-[#27262B] font-semibold text-lg text-center flex flex-col justify-center items-center">
                    Professional Certificate
                </h3>
            
            )}
            {i === 1 && (
                <div className=" h-44 bg-yellow-500 text-white text-center font-medium py-10 px-3 text-lg border border-gray-700  ">
                 Internship !
                </div>
            )}
            {i === 2 && (
                <div className='bg-[#27262B] py-8 px-6'>
                <div className="flex items-center justify-center gap-2 mb-1">
                    <Star className="w-4 h-4 fill-yellow-500 text-yellow-500" />
                    <h3 className="text-white font-semibold text-center text-lg">
                    Trusted Platform
                    </h3>
                </div>
                <div className="bg-[#27262B] text-gray-400 text-center text-base">view our reviews</div>
                <div className="text-yellow-500 bg-[#27262B] text-center mt-1 text-base">★★★★★</div>
                </div>
            )}
            {i === 3 && (
                <div className="bg-yellow-500 h-44 text-white text-center font-medium py-10 px-3 text-lg border border-gray-700">
                Affordable Price
                </div>
            )}
            {i === 4 && (
                <h3 className="text-white py-8 px-6 h-32 bg-[#27262B] font-semibold text-lg text-center flex flex-col justify-center items-center">
                Access Anywhere
                </h3>
            )}
            </div>
        ))}
        </div>

      {/* Square element */}
      <div className="absolute bottom-40 left-60">
        <div className="w-6 h-6 border-2 border-yellow-500 transform rotate-45"></div>
      </div>
    </div>
  )
}

