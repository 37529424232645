import React from 'react';
import {CiClock2} from "react-icons/ci";
import {IoVideocamOutline} from "react-icons/io5";
import {Link} from "react-router-dom";

const CourseCard = ( {courses} ) => {
    return (
        <>
            <Link to='/courses/comingSoon'>
                <div className=' w-auto md:max-w-96 border border-slate-200 rounded-2xl shadow-xl mx-2 md:mx-5 my-5'>
                    <img src={courses.img} alt='course'
                         className='w-full h-32 object-cover object-center rounded-t-2xl'/>
                    <div className='container mx-auto px-4'>

                        <p className='p-1 bg-gradient-to-r from-flight-500 font-montserrat text-sm text-black/50 w-fit mt-5'>Get
                            Guaranteed Certificate </p>
                        <h2 className='text-2xl  my-2 '>{courses.title}</h2>
                        <div className='flex gap-2 items-center my-3'>
                            <CiClock2 className='text-base'/>
                            <p className='font-montserrat'> 6 Months</p>
                        </div>
                        <div className='flex gap-2 items-center my-3'>
                            <IoVideocamOutline className='text-base'/>
                            <p className='font-montserrat'> Live Classes</p>
                        </div>
                        <h2 className='my-8 text-flight-500 text-xl font-montserrat '>Know more </h2>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default CourseCard;