import React from 'react';
import visual from '../assets/image/Visual Team photo or a visual representing innovation and collaboration.(3) 1.png'
import background from '../assets/image/Rectangle 34624129.png'
import calendra from '../assets/image/Calendar - Iconly Pro.png'
import { MdOutlineCurrencyRupee } from "react-icons/md";

const MasterClass = () => {
    const learn = [
        'The latest trends in digital marketing for 2024 and beyond',
        'How to create high-converting campaigns on social media',
        'Mastering SEO, SEM, and content marketing strategies',
        'Building data-driven marketing funnels for lead generation and conversion',
        'Email marketing strategies that work in today’s world',
        'Leveraging AI to boost your marketing efforts'

    ]

    return (
        <>
            <div className='flex flex-col'>
                <div className='relative'>
                    <img src={background} alt='background' className='w-full h-[500px] object-cover '/>
                    <div className='absolute top-0 left-0 w-full h-full bg-[#18171D] bg-opacity-50 '/>
                    <div className='flex justify-around absolute z-1 top-52 left-32 right-52  '>
                        <div className='flex flex-col mx-10 '>
                            <img src={visual} alt='visual' className='w-16 h-20 mx-10 '/>
                            <h1 className='text-6xl font-bold font-montserrat text-flight-500 mx-5 '>
                                Master the Future of
                                <span className='text-white'> Digital Marketing</span>
                            </h1>
                        </div>
                        <div className='bg-white rounded-3xl flex flex-col gap-1'>
                            <div className='my-5 flex gap-5 justify-center items-center '>
                                <img src={calendra} alt='calendra' className='w-[90] h-[90px] ml-5 '/>
                                <span
                                    className=' w-80  text-left  mr-5 text-4xl  font-semibold font-montserrat text-black'>
                                    Sunday 21st September
                                </span>
                            </div>
                            <button
                                className='bg-flight-500 w-full h-full rounded-3xl text-3xl font-semibold font-montserrat py-4 '>
                                Register Now for Free
                            </button>
                        </div>
                    </div>
                </div>
                <div className='bg-flight-500'>
                    <div className=' flex flex-col align-middle container mx-auto px-4  py-10'>
                        <h6 className='text-[40px] font-bold font-montserrat my-5  '>
                            Stay ahead in the ever-evolving world of digital marketing!
                        </h6>
                        <p className='text-4xl text-justify font-medium  font-montserrat my-5 '>
                            Join us for an intensive masterclass where industry experts will share the latest
                            strategies, tools, and techniques to help you dominate your digital marketing efforts.
                        </p>
                    </div>
                </div>

                <div className='container mx-auto px-8 py-10'>
                    <div className='flex flex-col gap-10 my-10 '>
                        <h1 className='text-5xl font-semibold font-montserrat'>
                            What You'll Learn
                        </h1>
                        <ul className='list-disc custom-disc px-20 space-y-6'>
                            {learn.map(( learn, index ) => (
                                <li key={index} className='text-[32px] text-justify font-medium px-1  font-montserrat '>
                                    {learn}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className=' relative border-[15px] border-flight-400 p-16 mt-20'>
                        <div className='w-96 h-24 absolute -top-12 left-0 bg-white '>
                            <h1 className='text-5xl text-center font-semibold font-montserrat  px-5'>
                                Why Attend?
                            </h1>
                        </div>
                        <ul className='list-disc px-16 custom-disc space-y-8 leading-3'>
                            <li className='font-medium font-montserrat text-4xl'>
                                Ideal for entrepreneurs, marketers, and small business owners
                            </li>
                            <li className='font-medium font-montserrat text-4xl'>
                                Practical insights to immediately apply to your marketing efforts
                            </li>
                            <li className='font-medium font-montserrat text-4xl'>
                                Learn from leading professionals in digital marketing
                            </li>
                        </ul>
                    </div>

                    <div className='px-10 flex flex-col gap-3 my-10 space-y-4 ' >
                        <h6 className='text-5xl font-medium font-montserrat ' >Exclusive Price</h6>
                        <p className='text-4xl font-medium font-montserrat' >
                            As part of our introductory offer
                        </p>
                        <div className='flex '>
                            <button
                                className='bg-flight-500 text-left text-4xl font-semibold font-montserrat py-6 border-2 drop-shadow-xl hover:bg-yellow-600 hover:drop-shadow-2xl border-red-600 px-5 rounded-xl'>
                                Register Now for Free
                            </button>
                            <p className='mx-5 text-4xl font-montserrat font-medium flex text-center items-center' >Original Price <MdOutlineCurrencyRupee/>999 </p>
                        </div>
                        <p className='my-2 text-4xl font-medium font-montserrat' >Limited Seats Available - Right Now</p>

                    </div>

                    <div className=' my-5 border-4 border-flight-500 rounded-2xl p-16 '>
                            <p className='text-[32px] text-center font-medium font-montserrat  ' >Don’t miss out on this opportunity to elevate your digital marketing game. Click the button below and secure your spot before it’s too late.</p>
                        <div className='flex justify-center items-center my-5' >
                            <button
                                className='bg-flight-500 text-left text-[32px] font-semibold font-montserrat py-6 drop-shadow-xl hover:bg-yellow-600 hover:drop-shadow-2xl  px-5 rounded-xl'>
                                Register Now for Free
                            </button>
                        </div>
                    </div>

                </div>


            </div>
        </>
    );
};

export default MasterClass;