import React from 'react';
import HeroSection from "../components/heroSection/heroSection";
import Service from "../components/Service";
import ChooseUs from "../components/chooseUs";
import Webinar from "../components/webinar";
import Certificate from "../components/certificate";
import Review from "../components/Review/Review";
import FAQ from "../components/FAQ";


const HomePage = () => {
    return (
        <>
            <HeroSection/>
            <Service/>
            <ChooseUs/>
            <Webinar/>
            <FAQ/>
            <Review/>
        </>
    );
};

export default HomePage;