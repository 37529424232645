import React from "react";
import TopBanner from "./topBanner";
import CoursePageContent from "./CoursePageContent";
import courseImg from '../../assets/image/courseCerti.png'
import CourseHero from "./courseHero";


const Details =
    [{
        title: "Full Stack Web Development",
        subTitle: "Learn to build modern web applications using MERN stack.",
        description: "Master the art of data-driven decision-making with our comprehensive Data Science program. Designed for aspiring data scientists, this course provides hands-on experience in data analysis, machine learning, and visualization tools to unlock the potential of big data.",
        image: "https://example.com/fullstack-course.jpg",
        duration: "6 months",
        price: "499",
        syllabus: [
            {
                module: 1,
                title: "Introduction to Data Science",
                description: "This module introduces the fundamental concepts of data science and its practical applications. Learners will gain familiarity with programming languages such as Python and \n" +
                    "R, essential for data analysis and manipulation..",
                topics: ["HTML Basics", "CSS Styling", "JavaScript Fundamentals"],
                minorProjects: [
                    {
                        number: 1,
                        title: " Data Cleaning and Analyzing"
                    }
                ]
            },
            {
                module: 2,
                title: "Backend Development with Node.js",
                description: "This module focuses on mastering data manipulation techniques and creating insightful visualizations using industry-standard tools.",
                topics: ["Node.js Basics", "Express Framework", "MongoDB Integration"],
                minorProjects: [
                    {
                        number: 2,
                        title: "Blog API"
                    }
                ]
            },
        ],
        projects: [
            "E-commerce Website",
            "Social Media Platform"
        ],

    }]


const CoursePage = () => {
    return (
        <div>
            {
                Details.map(( detail, index ) => (
                    <div key={index}>
                        <div>

                        <TopBanner title={detail.title} subTitle={detail.subTitle}/>
                        </div>

                        <CourseHero description={detail.description} price={detail.price}/>
                        <div className='container mx-auto px-4'>

                            <CoursePageContent syllabus={detail.syllabus}/>
                        </div>
                    </div>
                ))
            }
            <div>
                <img src={courseImg} alt="course certificate" className='w-full'/>
            </div>
           
        </div>
    );
};

export default CoursePage;
