import { useState, useRef, useEffect } from 'react'
import { Plus, Minus } from 'lucide-react'
import {Dialog, DialogPanel, DialogTitle} from '@headlessui/react'
import { motion } from 'framer-motion'
import {useDispatch} from "react-redux";
import {askQuestion} from "../redux/slice/faqSlice";


const faqs = [
    {
        question: "Are there any prerequisites to join this pack?",
        answer: "No prerequisites are required. A keen interest in learning and a commitment to personal growth are all you need."
    },
    {
        question: "Will I receive certification after completion?",
        answer: "Yes, participants will receive a certificate of completion for both the live classes and the internship."
    },
    {
        question:" How does the guaranteed internship work?",
        answer: "We partner with leading companies and ensure every participant gets hands-on experience through internships. The internship will align with the skills learned in the live classes"
    },
    {
        question: "Who can enroll in your programs?",
        answer: "Our programs cater to students, young professionals, and career enthusiasts who wish to build their skills, gain practical experience, or enhance their portfolios with real-world projects."
    }
]

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [height, setHeight] = useState(0)

    const answerRef = useRef(null)



    useEffect(() => {
        if (isOpen) {
            const answerEl = answerRef.current
            if (answerEl) {
                setHeight(answerEl.scrollHeight+20)
            }
        } else {
            setHeight(0)
        }
    }, [isOpen])

    return (
        <div className="max-w-[550px] border-b border-gray-200 py-4 ">
            <button
                className="flex w-full items-center justify-between text-left hover:text-black text-[#E4E3E8]/50 duration-300 ease-in-out "
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className=''>

                <span className="text-lg  font-montserrat">{question}</span>
                </div>
                <div className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                    {isOpen ? (
                        <Minus className="h-5 w-5 text-gray-500" />
                    ) : (
                        <Plus className="h-5 w-5 text-gray-500" />
                    )}
                </div>
            </button>
            <div
                className="overflow-hidden transition-all duration-300 ease-in-out mt-5"
                style={{ maxHeight: `${height}px` }}
            >
                <div ref={answerRef}>
                    <p className=" text-gray-600">{answer}</p>
                </div>
            </div>
        </div>
    )
}
const Faq = () => {
    let [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const[error, setError] = useState(null)
    const handleSubmit = () => {
        let errorMessage = null
        if(!data.email || !data.question){
           errorMessage=('Please fill all the fields')
        }
        if(errorMessage){
            setError(errorMessage)
        }else{
            setError(null)
            dispatch(askQuestion(data))
            setIsOpen(false)
        }
    }

    return (
        <>
            <div className='bg-flightDark-950 '>
                <div className='container mx-auto px-5'>

                <div className='  flex flex-col lg:flex-row justify-around items-center py-24'>
                    <div className='flex flex-col gap-5 items-start'>
                        <h2 className='text-xl font-montserrat font-medium text-white'>Frequently Ask Questions</h2>
                        <h2 className='text-2xl font-montserrat font-bold text-white mb-1'>
                            Any Question ? Find here
                        </h2>
                        <div className='bg-flight-500 cursor-pointer hover:bg-flight-600 text-white px-3 py-4 rounded-lg' onClick={() => setIsOpen(true)}>
                            <h2 className='text-lg font-montserrat font-medium'>Send Message</h2>
                        </div>

                        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50 w-96">
                            {isOpen && (
                                <motion.div
                                    className="fixed inset-0 bg-black/50 z-0"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                />
                            )}
                            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                                <motion.div
                                    initial={{ opacity: 0, x: -50}}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, y: 50 }}
                                    transition={{ duration: 0.4, ease: 'easeIn' }}
                                >
                                    <DialogPanel className="max-w-lg space-y-4 border bg-flightDark-950 p-4 rounded-lg shadow-lg">

                                        {
                                            error && (
                                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                                    {error}
                                                </div>
                                            )
                                        }

                                        <DialogTitle>
                                            <h1 className="text-white text-center text-2xl">Ask Question</h1>
                                        </DialogTitle>
                                        <div className="bg-white p-4 rounded-lg">
                                            <form>

                                                <div className="flex flex-col gap-4 my-4">
                                                    <label htmlFor="email" className="text-sm">
                                                        Email
                                                    </label>
                                                    <input
                                                        onChange={handleChange}
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="border border-gray-300 p-2 rounded-md"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-4">
                                                    <label htmlFor="name" className="text-sm">
                                                        Question
                                                    </label>
                                                    <input
                                                        onChange={handleChange}
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        className="border border-gray-300 p-2 rounded-md"
                                                    />
                                                </div>
                                                <div className="flex justify-center items-center my-4">
                                                    <button
                                                        onClick={handleSubmit}
                                                        className="bg-flight-500 text-white p-2 rounded-md"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </DialogPanel>
                                </motion.div>
                            </div>
                        </Dialog>


                    </div>
                    <div className='px-4 py-5 sm:p-6'>
                        {faqs.map(( faq, index ) => (
                            <div key={index}
                                 className="transition duration-300 ease-in-out hover:bg-gray-50 hover:text-black">
                                <FAQItem question={faq.question} answer={faq.answer}/>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default Faq;