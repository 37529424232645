import React, {useState} from 'react';
import blogImg from '../assets/image/blog.png'
import blogMobile from '../assets/image/blogMobile.png'

//Redux
import {useDispatch} from 'react-redux'
import {subscribe} from "../redux/slice/subscribe.slice";

const Blog = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(subscribe({email}))
        setEmail('')
    }

    const [allPost, setAllPost] = useState(false)
    const [popularPost, setPopularPost] = useState(false);


    const recentPosts = [
        {
            title: 'Revolutionizing Business with AI',
            description: 'Discover how artificial intelligence is transforming industries and what the future holds for AI-driven businesses.',
            category: 'AI',
        },
        {
            title: 'The Future of Digital Marketing',
            description: 'Learn how digital marketers are preparing for the shift away from third-party cookies and what strategies will keep your campaigns relevant.',
            category: 'Digital Marketing',
        },
        {
            title: 'Top Industry Trends to Watch in 2024',
            description: 'Stay ahead of the curve by exploring the most significant trends shaping industries today, from technology to marketing.',
            category: 'Industry Trends',
        }, {
            title: 'Top Industry Trends to Watch in 2024',
            description: 'Stay ahead of the curve by exploring the most significant trends shaping industries today, from technology to marketing.',
            category: 'Industry Trends',
        }, {
            title: 'Top Industry Trends to Watch in 2024',
            description: 'Stay ahead of the curve by exploring the most significant trends shaping industries today, from technology to marketing.',
            category: 'Industry Trends',
        }, {
            title: 'Top Industry Trends to Watch in 2024',
            description: 'Stay ahead of the curve by exploring the most significant trends shaping industries today, from technology to marketing.',
            category: 'Industry Trends',
        },
    ]
    const popularPosts = [
        {
            title: 'The Role of AI in Modern Education',
            description: 'A deep dive into how artificial intelligence is revolutionizing the education sector and enhancing learning experiences.',
            category: 'Training and Eduction',
        },
        {
            title: 'Key SEO Strategies You Need for 2024',
            description: 'Learn the latest SEO techniques that will keep your content ranking high and boost your visibility.',
            category: 'Digital Marketing',
        },
        {
            title: 'Trending AI Innovations in Business',
            description: 'Explore the latest artificial intelligence innovations that are transforming business operations, driving efficiency, and setting new industry standards.',
            category: 'AI',
        }
    ]
    const topics =[
        {
            title: 'AI',
            syllabus:[
                {
                    heading: 'Trending AI Tools Revolutionizing Industries',
                    description: 'Discover the cutting-edge AI tools that are reshaping various industries and driving innovation.'
                },
                {
                    heading: 'The Future of AI: What’s Next?',
                    description: 'Get insights into the upcoming advancements and trends shaping the future of artificial intelligence.'
                },{
                    heading:'AI Innovations Transforming Business Operations',
                    description: 'Explore how AI is enhancing efficiency and innovation in modern business practices.'
                }
                ]
        }, {
            title: 'Digital Marketing',
            syllabus:[
                {
                    heading: 'Effective Digital Marketing Campaigns You Should Know',
                    description: 'Discover successful digital marketing campaigns and what makes them stand out.'
                },
                {
                    heading: 'Top Digital Marketing Metrics to Track in 2024',
                    description: 'Learn about the key metrics that will drive your digital marketing success this year.'
                },{
                    heading:'Trending Email Marketing Tactics',
                    description: 'Uncover the email marketing tactics that are trending and how they can enhance your campaigns'
                }
                ]
        }, {
            title: 'Training & Education',
            syllabus:[
                {
                    heading: 'Best Practices for Online Learning Success',
                    description: 'Find out the most effective strategies for succeeding in online education and training.'
                },
                {
                    heading: 'Innovative Training Methods for Skill Development',
                    description: 'Explore the latest methods in training that are helping professionals develop critical skills.'
                },{
                    heading:'How to Implement Effective Training Programs',
                    description: 'Learn best practices for designing and implementing training programs that yield real results.'
                }
                ]
        },
    ]



    const postToShow = allPost ? recentPosts : recentPosts.slice(0, 3)
    const popularPostToShow = popularPost ? popularPosts : popularPosts.slice(0, 3)
    return (
        <>
            <div className='flex flex-col'>
                <div className='w-full relative '>
                    <img src={blogImg} alt='blog' className=' hidden md:flex w-full h-[500px] object-cover '/>
                    <div className='hidden md:flex absolute top-0 left-0 w-full h-full bg-[#18171D] bg-opacity-50 '/>
                    <img src={blogMobile} alt='blog' className='md:hidden w-full  object-cover '/>
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
                        <h2 className='text-3xl md:text-4xl font-bold font-montserrat text-white md:text-flight-500'>
                            Our Blog
                        </h2>
                        <p className=' hidden md:flex text-3xl font-semibold font-montserrat text-white align-middle '>
                            Insights, tips, and trends from the world of AI, digital marketing, and more. </p>
                    </div>
                </div>
                <div className='container mx-auto px-4 py-10'>
                    <div className='flex flex-col my-5 '>
                        <h3 className='text-3xl font-semibold font-montserrat '>
                            Recent Posts
                        </h3>
                        <div className='md:flex justify-between my-5 '>
                            <div className='  grid grid-cols-1  md:grid-cols-3  my-5  '>
                                {postToShow.map(( post, index ) => (
                                    <div key={index}
                                         className=' bg-neutral-200 md:bg-white 2xl:w-96 w-80 p-5 border border-slate-200 rounded-2xl flex flex-col  gap-1 my-5 mx-3 '>
                                        <h3 className='text-lg md:text-2xl font-semibold font-montserrat '>
                                            {post.title}
                                        </h3>
                                        <p className='text-base md:text-lg font-medium font-montserrat  '>
                                            {post.description}
                                        </p>

                                        <p className='text-base md:text-lg font-medium font-montserrat mt-auto  '>
                                            Category: {post.category}
                                        </p>
                                    </div>

                                ))}
                            </div>
                            <div className='flex justify-center  items-center ' >
                                <button
                                    onClick={() => setAllPost(!allPost)}
                                    className=' 2xl:hidden h-14 w-40 md:w-20 md:h-20 bg-flight-500 text-center text-black px-4 py-2 rounded-xl md:rounded-full font-medium hover:bg-flight-600 border border-red-600 shadow-xl '>
                                    {allPost ? 'Show Less' : 'Read More'}
                                </button>

                                <button
                                    onClick={() => setAllPost(!allPost)}
                                    className='hidden  2xl:inline h-20  bg-flight-500 text-center text-black px-6 py-2 border border-red-600 shadow-xl rounded-2xl font-medium hover:bg-flight-600'>
                                    {allPost ? 'Show Less' : 'Read More'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col my-5 '>
                        <h3 className='text-3xl font-semibold font-montserrat '>
                            Popular Posts
                        </h3>
                        <div className='md:flex justify-between my-5 '>
                            <div className='  grid grid-cols-1 md:grid-cols-3  my-5  '>
                                {popularPostToShow.map(( post, index ) => (
                                    <div key={index}
                                         className=' bg-neutral-200 md:bg-white 2xl:w-96 w-80 p-5 border border-slate-200 rounded-2xl flex flex-col  gap-1 my-5 mx-3 '>
                                        <h3 className='text-lg md:text-2xl font-semibold font-montserrat '>
                                            {post.title}
                                        </h3>
                                        <p className='text-base md:text-lg font-medium font-montserrat  '>
                                            {post.description}
                                        </p>

                                        <p className='text-base md:text-lg font-medium font-montserrat mt-auto  '>
                                            Category: {post.category}
                                        </p>
                                    </div>

                                ))}
                            </div>
                            <div className='flex justify-center  items-center '>
                                <button
                                    onClick={() =>setPopularPost(!popularPost)}
                                    className='2xl:hidden  h-14 w-40 md:w-20 md:h-20 bg-flight-500 text-center text-black px-4 py-2 border border-red-600 shadow-xl rounded-xl md:rounded-full font-medium hover:bg-flight-600'>
                                    {allPost ? 'Show Less' : 'Read More'}
                                </button>

                                <button
                                    onClick={() =>setPopularPost(!popularPost)}
                                    className='hidden 2xl:inline h-20 bg-flight-500 text-center text-black px-6 py-2 border border-red-600 shadow-xl rounded-2xl font-medium hover:bg-flight-600'>
                                    {allPost ? 'Show Less' : 'Read More'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col my-5'>
                        <h3 className='text-3xl font-semibold font-montserrat '>
                            Topics
                        </h3>
                    </div>
                    <div className=' flex-col md:flex-row flex justify-between gap-10 md:gap-5 ' >
                        {topics.map(( topic, index ) => (
                            <div className='bg-neutral-200 md:bg-white border border-slate-200 rounded-lg md:rounded-2xl '>
                                <div className='flex items-center  '>
                                    <h3 className=' text-xl md:text-2xl font-semibold font-montserrat px-10 py-2'>
                                        {topic.title}
                                    </h3>
                                </div>
                                <div className='border-b border-slate-400 md:border-slate-200 w-full '/>
                                <div className='px-12 py-5'>
                                    {topic.syllabus.map(( syllabus, index ) => (
                                        <div className='flex flex-col my-2 gap-2 '>
                                            <h4 className='text-lg md:text-xl font-semibold font-montserrat'>
                                                {syllabus.heading}
                                            </h4>
                                            <p className='text-base md:text-lg font-light font-montserrat mb-3 '>
                                                {syllabus.description}
                                            </p>

                                        </div>

                                    ))}
                                </div>
                                <div className='border-b border-slate-200 w-full '/>
                                <div className='hidden md:flex items-center justify-center  '>
                                    <h3 className='text-2xl font-medium font-montserrat px-10 py-2 text-center'>
                                        Read More
                                    </h3>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <footer className='hidden md:inline  relative bg-flight-500 h-[450px]'>
                    <div className='mt-20 mr-96  h-full bg-gradient-to-tr from-black to-transparent'/>
                    <div className='bg-flight-500 w-40 h-16 absolute top-80 '/>
                    <div className='bg-flight-500 w-96 h-16 absolute top-96 left-40 '/>
                    <h1 className='text-6xl max-w-[505px] text-pretty font-medium absolute top-20 left-36 text-white font-montserrat ' >Subscibe to<br/>Our Newsletter</h1>
                    <p className='font-montserrat font-medium text-2xl text-white w-80 absolute top-32    right-[450px] ' >Join our newsletter for the latest updates and exclusive content!</p>

                        <form onSubmit={handleSubmit} className='flex  absolute top-80 right-48 '>
                            <input id='email' value={email} onChange={
                                (e) => setEmail(e.target.value)
                            } className='w-96 px-5 py-6 bg-white text-slate-500 ' placeholder='youremail@gmail.com'/>
                            <button className=' text-2xl bg-[#18171D] text-flight-500 px-6 py-6 font-bold tracking-widest '>SUBSCRIBE</button>
                        </form>
                    <div className='absolute top-64 bg-gradient-to-r from-black w-48 right-0 h-16'/>

                </footer>

            </div>
        </>
    );
};

export default Blog;