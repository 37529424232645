import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const askQuestion = createAsyncThunk('faq/askQuestion', async (data, thunkAPI) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/faq/askQuestion`, data);
        return response.data;
    }catch (err){
        return thunkAPI.rejectWithValue(err.response.data);
    }
})

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        questionAsked: false,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(askQuestion.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(askQuestion.fulfilled, (state) => {
                state.loading = false;
                state.questionAsked = true;
            })
            .addCase(askQuestion.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
    },
    devTools: true
})

export default faqSlice.reducer;