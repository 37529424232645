import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Lock } from 'lucide-react';

//Redux
import {useDispatch} from "react-redux";
import {resetPassword} from "../../redux/slice/forgotPassword.slice";

export default function ResetPassword() {
  const {token} = useParams();
  console.log(token);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      setError("Please fill out both password fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      dispatch(resetPassword({password: newPassword, token}));
      navigate("/auth"); // Redirect to login page
    } catch (error) {
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="relative h-screen w-full max-w-full my-0 mx-auto py-20 bg-gradient-to-b from-yellow-200 to-yellow-500 px-15 flex flex-col items-center overflow-hidden">
      {/* Background Circles - Top Left */}
      <div className="absolute top-0 left-0 w-[800px] h-[800px] -translate-x-1/2 -translate-y-1/2">
        <div className="absolute inset-0 rounded-full bg-[#FFC500] opacity-50"></div>
        <div className="absolute inset-[10%] rounded-full bg-yellow-300 opacity-50"></div>
        <div className="absolute inset-[20%] rounded-full bg-yellow-200 opacity-50"></div>
        <div className="absolute inset-[30%] rounded-full bg-yellow-100 opacity-50"></div>
      </div>
      {/* Background Circles - Bottom Right */}
      <div className="absolute bottom-0 right-0 w-[800px] h-[800px] translate-x-1/2 translate-y-1/2">
        <div className="absolute inset-0 rounded-full bg-[#FFC500] opacity-50"></div>
        <div className="absolute inset-[10%] rounded-full bg-yellow-300 opacity-50"></div>
        <div className="absolute inset-[20%] rounded-full bg-yellow-200 opacity-50"></div>
        <div className="absolute inset-[30%] rounded-full bg-yellow-100 opacity-50"></div>
      </div>

      {/* Form Content */}
      <div className="relative p-10 my-16 bg-white flex flex-col rounded-2xl w-96 shadow-neutral-600 shadow-lg">
        <h1 className="text-2xl p-0 font-bold text-neutral-950 font-montserrat">Reset Password</h1>
        <form className="flex flex-col w-full pt-5" onSubmit={handleSubmit}>
          <div className="relative w-full mb-5">
            <input
              type="password"
              autoComplete="new-password"
              className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <Lock className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400" />
          </div>

          <div className="relative w-full mb-5">
            <input
              type="password"
              autoComplete="confirmPassword"
              className="w-full bg-neutral-200 py-3 px-4 pl-10 border-2 border-solid border-neutral-700 rounded-2xl placeholder:font-montserrat placeholder:font-bold placeholder:text-neutral-400"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <Lock className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-neutral-400" />
          </div>

          {error && (
            <div className="mt-3 text-red-500 text-sm">
              {error}
            </div>
          )}

          <div className="flex flex-row justify-between py-5">
            <Link to="/auth" className="text-yellow-500 font-bold font-montserrat">
              Sign In
            </Link>
            <Link to="/auth" className="text-yellow-500 font-bold font-montserrat">
              Registration
            </Link>
          </div>

          <button
            type="submit"
            className="bg-yellow-500 shadow-lg shadow-neutral-400 rounded-2xl text-white mx-10 mt-14 py-3 px-2 rounded-2 font-montserrat"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
