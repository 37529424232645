import React from 'react';
import Navbar from "./Navbar";


const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar/>
            <div className='container mx-auto px-4 my-10'>
                <h2 className='text-3xl font-montserrat font-bold text-center my-10 '>
                    Digimation Flight Service Policies

                </h2>
                <div className='font-montserrat my-10 flex flex-col gap-5 '>
                    <div>
                        <h3 className='text-xl font-bold'> 1. Service Agreement</h3>
                        <div className='px-5'>
                            By purchasing our services, clients agree to the scope, timelines, and deliverables outlined
                            in the initial proposal or service agreement.<br/>
                            All services provided by Digimation Flight are conducted in good faith and with professional
                            standards. Service details, including timelines, features, and expected outcomes, will be
                            clarified at the project’s outset.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 2. Payment Policy</h3>
                        <div className='px-5'>
                            Full payment must be made before the commencement of services unless otherwise agreed upon
                            in
                            the service contract. For larger projects, milestone-based payments may be arranged.<br/>
                            Delayed or missing payments may result in suspension of service until full payment is
                            received.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 3. No Refund Policy</h3>
                        <div className='px-5'>
                            Non-Refundable Services: By choosing Digimation Flight’s services, clients acknowledge that
                            all
                            payments are final. Refunds are not available after the service has been initiated or
                            delivered,
                            regardless of satisfaction levels or service outcomes.<br/>
                            Service Usage: Once our team begins working on a project or provides deliverables, the
                            service is
                            considered “used” and cannot be refunded, as resources and time have been allocated.<br/>
                            Pre-Service Cancellation: If a client wishes to cancel before the service begins, a request
                            can be
                            submitted, but refunds remain at the company’s discretion and may incur processing fees.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 4. Project Revisions and Modifications</h3>
                        <div className='px-5'>
                            We are committed to providing high-quality results as per the agreed specifications. Minor
                            adjustments and reasonable revisions are included in the initial service agreement. However,
                            substantial modifications outside the original scope may incur additional charges.<br/>
                            Clients should submit requests for revisions within 7 days of receiving deliverables to
                            qualify
                            for adjustments under the original agreement.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 5. Service Delivery</h3>
                        <div className='px-5'>
                            Digimation Flight will deliver all digital marketing, web services, and e-learning services
                            as
                            outlined in the project scope. Our team ensures prompt service delivery within the agreed
                            timeframe unless affected by circumstances outside of our control.<br/>
                            If unforeseen delays occur, Digimation Flight will notify the client and propose an adjusted
                            timeline.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 6. Liability Limitation</h3>
                        <div className='px-5'>
                            Digimation Flight is not liable for any indirect, incidental, or consequential damages
                            resulting
                            from the use of its services. Clients are responsible for the manner in which they utilize
                            the
                            deliverables, including but not limited to web services, digital marketing strategies, and
                            educational content.<br/>
                            It is the client's responsibility to review all deliverables for compliance with their brand
                            and
                            regulatory requirements before implementing them publicly.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 7. Confidentiality and Intellectual Property</h3>
                        <div className='px-5'>
                            All client data and project details are treated as confidential. Digimation Flight commits
                            to
                            safeguarding any sensitive information shared during the project.<br/>
                            Intellectual property rights of final deliverables will transfer to the client upon project
                            completion and full payment unless otherwise agreed. Any proprietary methods, software, or
                            processes used by Digimation Flight remain the company’s intellectual property.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 8. Service Termination</h3>
                        <div className='px-5'>
                            Digimation Flight reserves the right to terminate services if clients fail to adhere to the
                            terms,
                            such as delayed payments, misuse of services, or breach of confidentiality. In the case of
                            termination, all payments made up to that point are non-refundable.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 9. Dispute Resolution</h3>
                        <div className='px-5'>
                            Any disputes arising from service terms will be handled amicably and professionally.
                            However, if
                            resolution is not possible, disputes will be governed under the applicable laws in
                            Digimation
                            Flight’s jurisdiction.<br/>
                            Clients are encouraged to communicate concerns promptly to resolve issues efficiently and
                            avoid
                            misunderstandings.
                        </div>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold'> 10. Policy Updates</h3>
                        <div className='px-5'>
                            Digimation Flight reserves the right to update these policies periodically. Clients will be
                            notified of any major changes, and updates will be posted on our website.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;