import {useEffect} from "react";
import {useLocation, useNavigate } from "react-router-dom";

const GoogleCallbackHandler = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if(token){
            localStorage.setItem('token', token);
            navigate('/');
        }else {
            console.error('No token found in URL');
        }

    }, [location, navigate]);

    return null;
}

export default GoogleCallbackHandler;