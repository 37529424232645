import React from 'react';
import ReactStars from "react-stars/dist/react-stars";

const ReviewCard = ({ review }) => {
    return (
        <div className="flex flex-col gap-2 mx-4">
            <div className="bg-white w-full p-4 rounded-t-xl rounded-br-xl flex flex-col justify-between h-56">
                <div>
                    <ReactStars
                        size={30}
                        color2={'#FDD264'}
                        value={review.value}
                        edit={false}
                        className="px-5 mb-2"
                    />
                    <p className="text-[#27262B] text-base pl-4 pb-2 font-montserrat text-pretty">
                        {review.description}
                    </p>
                </div>
            </div>
            <div className="flex gap-2 items-center">
                <img
                    src="https://img.freepik.com/premium-photo/3d-avatar-cartoon-character_113255-103130.jpg"
                    alt="image"
                    className="w-14 h-14 rounded-full"
                />
                <h2 className="font-montserrat text-xl font-medium text-white">
                    {review.name}
                </h2>
            </div>
        </div>
    );
};

export default ReviewCard;
