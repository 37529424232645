import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {BsFillCalendarRangeFill} from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
const webinar = [
    {
        title: 'Master the Future of Digital Marketing',
        date: '22th sep',
        time: '1:00pm',
    },
    {
        title: "How Ai revolutionize the world",
        date: '25th sep',
        time: '2:00pm',
    },
]

let settings = {
    dots: false, // Disable dots
    speed: 500,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable arrows
    infinite: false, // Prevent infinite scrolling for scrollbar usability
};




const Webinar = () => {
    function adjustCardHeights() {
        const cards = document.querySelectorAll(".webinar-card");
        let maxHeight = 0;

        // Find the tallest card
        cards.forEach((card) => {
            card.style.height = "auto"; // Reset height to auto to recalculate
            maxHeight = Math.max(maxHeight, card.offsetHeight);
        });

        // Apply the tallest height to all cards
        cards.forEach((card) => {
            card.style.height = `${maxHeight}px`;
        });
    }

    useEffect(() => {
        adjustCardHeights();
        window.addEventListener("resize", adjustCardHeights);

        return () => {
            window.removeEventListener("resize", adjustCardHeights);
        };
    }, []);

    return (
        <>
            <div className='bg-[#18171D]  font-montserrat'>
                <div className='container mx-auto p-10'>
                    <div className='my-5 '>
                        <h2 className='text-3xl text-center font-semibold text-white '>Upcoming Webinars</h2>
                    </div>
                    <div className='hidden  md:grid grid-cols-2 gap-14 py-5 px-20'>
                        {
                            webinar.map(( webinar, index ) => (
                                <div key={index} className='rounded-md bg-[#27262B]'>
                                    <div className=' p-10'>
                                        <h2 className='text-2xl  text-gray-100 py-4 '>{webinar.title}</h2>
                                        <div className='flex py-5 gap-5 items-center '>
                                            <BsFillCalendarRangeFill className='text-white w-10 h-10 '/>
                                            <p className='text-white text-lg pl-2'>{webinar.date} | {webinar.time} </p>
                                        </div>

                                        <div className='flex justify-center items-center pt-10'>
                                            <Link to='/webinar'
                                                  className='text-white border-2 border-flight-500 rounded-xl px-5 py-1 text-lg'>Register
                                                Now</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="md:hidden mx-2">
                        <Slider {...settings} className="flex items-stretch">
                            {webinar.map(( webinar, index ) => (
                                <div
                                    key={index}
                                    className="webinar-card flex flex-col border-4 border-flight-500 rounded-lg bg-slate-900"
                                >
                                    <div className="p-10 flex-grow flex flex-col justify-between">
                                        <h2 className="text-xl text-gray-100 py-4">{webinar.title}</h2>
                                        <div className="flex py-5 gap-5 items-center">
                                            <BsFillCalendarRangeFill className="text-white w-7 h-7"/>
                                            <p className="text-white text-md pl-2">
                                                {webinar.date} | {webinar.time}
                                            </p>
                                        </div>

                                        <div className="flex justify-center items-center pt-10">
                                            <Link
                                                to="/webinar"
                                                className="text-white border-2 border-flight-500 rounded-xl px-5 py-1"
                                            >
                                                Register Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>


                </div>
            </div>
        </>
    );
};

export default Webinar;