import React from 'react';
import logo from '../assets/image/Logo-white[1].png'
import {FaInstagram, FaLinkedin, FaWhatsapp, FaYoutube} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <>

            <div className=" hidden md:block relative  w-full bg-yellow-950 ">

                <div className='bg-[#18171D] p-10'>
                    <div className=' container mx-auto  '>
                        <div className='flex justify-around gap-5 '>
                            <div className=' flex  flex-col gap-10'>
                                <div className='flex items-center'>
                                    <img src={logo} alt='logo' className='w-12'/>
                                    <h2 className='text-white font-bold text-2xl font-[inter]'>Digimation Flight</h2>
                                </div>
                                <div className='flex items-center gap-5 '>
                                    <form className="flex space-x-2">
                                        <input
                                            type="email"

                                            placeholder="Enter your email"
                                            className=" flex-grow w-80 px-3 py-2 rounded-md bg-transparent text-gray-300 placeholder-gray-500 border border-gray-800 focus:outline-none focus:border-gray-700"
                                            required
                                        />
                                        <button
                                            type="submit"
                                            className="px-4 py-2 rounded-md text-gray-300
                                                 bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-sm
                                                 hover:bg-[rgba(255,255,255,0.1)] focus:outline-none
                                                 transition duration-300 ease-in-out"
                                        >
                                            Subscribe
                                        </button>
                                    </form>
                                </div>

                            </div>
                            <div className='flex justify-between gap-20 '>
                                <div className='flex flex-col gap-5 text-left px-16 '>
                                    <h3 className='text-white z1 text-lg font-medium'>Quick Links</h3>
                                    <Link to='/' className='text-slate-300 text-base'>Home </Link>
                                    <Link to='/about' className='text-slate-300 text-base'>About </Link>
                                    <Link to='/blogs' className='text-slate-300 text-base'>Blog</Link>
                                    <Link to='/contact' className='text-slate-300 text-base'>contact</Link>
                                    <Link to='/campusAmbassador' className='text-slate-300 text-base'>Student
                                        Ambassador</Link>
                                </div>
                                <div className='flex flex-col gap-5 text-left px-16 '>
                                    <h3 className='text-white z1 text-lg font-medium'>Contact Information</h3>
                                    <div className='flex gap-2 items-center '>
                                        <IoMdMail className='text-slate-300 w-6 h-6 '/>
                                    <p className='text-slate-300 text-base'> contact@digimationflight.com </p>x
                                    </div>
                                    <div className='flex gap-5 items-end'>
                                        <a href='https://www.instagram.com/digimationflight'
                                           target="_self"
                                        >
                                            <FaInstagram className='text-slate-300 w-8 h-8 '/>
                                        </a>
                                        <a href='https://www.linkedin.com/company/digimationflight/'
                                           target='_self'>
                                            <FaLinkedin className='text-slate-300 w-8 h-8 '/>
                                        </a>
                                        <a href='https://www.youtube.com/@digimationflight' target='_self'>
                                            <FaYoutube className='text-slate-300 w-8 h-8 '/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='border-b-2 border-gray-800 my-5'></div>


                        <div className='flex justify-between items-center px-5 '>
                            <div
                                className='flex items-center justify-center text-slate-400 gap-6 font-medium text-base' onClick={() => {

                            }}>
                                <h3>
                                    <a href='/privacy-policy'>
                                        Privacy Policy
                                    </a>
                                </h3>
                                <h3 className='text-center'>
                                    .
                                </h3>
                                <h3>
                                    <a href='/privacy-policy' target='_top'>
                                        Terms of Service
                                    </a>
                                </h3>
                            </div>
                            <div className='text-slate-400'>
                                <p>© 2025 Digimation Flight. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Mobile Footer*/}

            <div className='md:hidden w-full h-full bg-[#18171D] py-10 '>
                <div className='container mx-auto px-4'>
                    <div className=' flex mb-4 '>
                        <img src={logo} alt='logo' className='w-11 h-11'/>
                        <h2 className='py-2 px-2 text-white font-medium text-xl font-[inter]'>Digimation Flight</h2>
                    </div>
                    <div className='flex items-center my-10 gap-5 '>
                        <form className="flex gap-6">
                            <input
                                type="email"

                                placeholder="Enter your email"
                                className=" flex-grow w-1/2 px-3 py-2 rounded-md bg-transparent text-gray-300 placeholder-gray-500 border border-gray-800 focus:outline-none focus:border-gray-700"
                                required
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 rounded-md text-gray-300
                     bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-sm
                     hover:bg-[rgba(255,255,255,0.1)] focus:outline-none
                     transition duration-300 ease-in-out"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                    <div className='border-b-2 my-10 border-white'/>
                    <div className='flex flex-col my-10'>

                        <div className='flex flex-col gap-6 text-left my-5 '>
                            <h3 className='text-white font-montserrat  text-2xl font-bold'>Quick Links</h3>
                            <Link to='/' className=' font-montserrat text-white text-xl font-semibold'>Home </Link>
                            <Link to='/about' className='font-montserrat text-white text-xl font-semibold'>About </Link>
                            <Link to='/service'
                                  className='font-montserrat text-white text-xl font-semibold'>Service</Link>
                            <Link to='/blogs' className='font-montserrat text-white text-xl font-semibold'>Blog</Link>
                            <Link to='/contact'
                                  className='font-montserrat text-white text-xl font-semibold'>Contact</Link>
                        </div>
                        <div className='my-5'>
                            <h3 className='text-white font-montserrat  text-2xl font-bold my-5'>Join us</h3>
                            <div className='flex gap-5 items-end'>
                                <a href='https://www.instagram.com/digimationflight' target='_self'>
                                    <FaInstagram className='text-slate-300 w-8 h-8 '/>
                                </a>
                                <a href='https://www.linkedin.com/company/digimationflight/'
                                   target='_self'>
                                    <FaLinkedin className='text-slate-300 w-8 h-8 '/>
                                </a>
                                <a href='https://wa.me/917017768577' target='_self'>
                                    <FaWhatsapp className='text-slate-300 w-8 h-8 '/>
                                </a>
                            </div>
                        </div>
                        <div className='border-b-2 my-10 border-white'/>
                        <div className='text-white'>
                            <p>© 2024 Digimation Flight. All rights reserved.</p>
                        </div>
                    </div>


                </div>
            </div>

        </>
    );
};

export default Footer;