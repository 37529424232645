import React from "react";

const CourseModule = ({
  module,
  title,
  description,
  topics,
  minorProjects = [], // Default to an empty array
}) => {
  return (
    <div className="sm:h-4/5 h-auto w-full max-w-2xl bg-black px-5 md:px-12 py-20 flex flex-col justify-center gap-4">
      {/* Module Title */}
      <h2 className="font-montserrat text-left mb-2 text-2xl text-neutral-200">
        Module {module}: {title}
      </h2>

      {/* Module Description */}
      <p className="text-neutral-200 font-montserrat text-sm mb-8 md:w-3/4 md:text-left">{description}</p>

      {/* Topics and Minor Projects */}
      <div className="flex flex-row justify-between w-full gap-4">
        {/* Topics Section */}
        <div className="bg-neutral-800 p-4 w-auto flex-1 shadow-inner shadow-black">
          <h3 className="text-neutral-200 font-montserrat font-semibold mb-4 text-center">Topics</h3>
          <ul className="list-disc text-sm font-montserrat text-balance text-neutral-200 ml-4 space-y-1">
            {topics.map((topic, index) => (
              <li key={index}>{topic}</li>
            ))}
          </ul>
        </div>

        {/* Minor Projects Section (Optional) */}
        {minorProjects.length > 0 && (
          <div className="bg-neutral-800 p-4 flex-1 shadow-inner shadow-black ">
            <h3 className="text-neutral-200 font-montserrat font-semibold mb-4 text-center">Minor Projects</h3>
            <ul className="list-disc font-montserrat text-neutral-200 ml-4 space-y-1 text-sm text-balance ">
              {minorProjects.map((project, index) => (
                <li key={index}>
                  #{project.number} {project.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseModule;
