import React from 'react'
import { IndianRupee } from 'lucide-react';


const AboutCourse = ({description, price}) => {
  return (
    <div className="max-w-full w-full mx-auto flex items-center justify-center">
    <div className="relative w-full bg-white min-h-[400px] px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-7xl mx-auto">
        {/* Pendulum Price Tag */}
        <div className="absolute -top-8 right-4 sm:right-6 lg:right-8 mt-4 sm:mt-6 lg:mt-8">
          <div className="relative">
            {/* Pendulum string */}
            <div className="hidden md:flex absolute left-1/2 -top-12 sm:-top-16 w-[2px] sm:w-[3px] lg:w-[4px] h-20 sm:h-20 bg-black transform -translate-x-1/2" />
            
            {/* Circular price tag with gradient border */}
            <div className="relative top-5 w-28 h-28 sm:w-36 sm:h-36 lg:w-48 lg:h-48 rounded-full bg-gradient-to-r from-yellow-500 via-yellow-700 to-black p-1 sm:p-2 shadow-lg">
              {/* Inner content of the price tag */}
              <div className="w-full h-full rounded-full bg-black flex flex-col items-center justify-center">s
                <div className="text-[10px] sm:text-xs lg:text-sm text-gray-400 mb-0.5 sm:mb-1">Price</div>
                <div className=" flex items-center  text-base sm:text-lg lg:text-xl font-bold text-white mb-1 sm:mb-2 lg:mb-4"> <IndianRupee size='22'/>   {price} /-</div>
                <button
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold rounded-xl border-none text-[10px] sm:text-xs lg:text-sm px-2 py-0.5 sm:px-4 sm:py-1 lg:px-6 lg:py-2"
                  aria-label="Buy Data Science Course"
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Course Info */}
        <div className="pt-32 sm:pt-40 lg:pt-0 lg:mt-24">
          <div className="max-w-2xl px-4">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
              About Data Science Course
            </h2>
            <p className="text-gray-600 leading-relaxed max-w-xl">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default AboutCourse;

