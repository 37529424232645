import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";


export const subscribe = createAsyncThunk('subscribe/subscribe', async ( {email}, thunkAPI ) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, {email});
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState: {
        subscribed: false,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder.addCase(subscribe.pending, ( state ) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(subscribe.fulfilled, ( state ) => {
                state.loading = false;
                state.subscribed = true;
            })
            .addCase(subscribe.rejected, ( state, action ) => {
                state.loading = false;
                state.error = action.payload.message;
            })
    },

    devTools: true
})

export default subscribeSlice.reducer;